/*
Possible optimization: to prevent multiple instances (and having to calculate everything each time), use singleton
if the record passed in is not different from the one already set. if it differs, destroy
and create new instance with new record data.
 */
export class ProjectScoreCalc {
  scoreKeys = [
    'debtServiceCoverageRatio',
    'balanceSheetRatio',
    'ficoCreditAverage',
    'loanToValue',
    'guarantorCharacter',
  ];
  debtServiceCoverageRatio = null;
  balanceSheetRatio = null;
  ficoCreditAverage = null;
  loanToValue = null;
  guarantorCharacter = null;

  // store the projectFinancingSources rec where the name=Personal
  personalFinancingSource = null;

  hasMissing = false; // true if ANY score has missing data
  apprStatCodes = {
    'AP': {display:'Approved'},
    'OK': {display:'OK'},
    'PD': {display:'Pending'},
    'NR': {display:'Needs Review'},
    'RJ': {display:'Rejected'}
  }

  scoreRules = null;
  approvalStatus = 'PD'; // can be "PD", "AP", "NR", "RJ"
  approvalStatusBy = null; // the user that set the project status to approved or rejected
  approvalStatusAt = null; // the time the user set the project status

  constructor(record) {
    //console.log('PSC record', record);
    this.record = record;
    this._setScoreRules();

    this._calcDebtServiceCoverageRatio();
    this._calcBalanceSheetRatio();
    this._calcFicoCreditAverage();
    this._calcLoanToValue();
    this._calcGuarantorCharacter();

    this._setHasMissing();
    this._setApprovalStatus();
  }

  _setApprovalStatus() {
    // if already manually approved, set to whatever they set
    if (this.record.approvalStatusBy) {
      this.approvalStatus = this.record.approvalStatus;
      this.approvalStatusBy = this.record.approvalStatusBy;
      this.approvalStatusAt = this.record.approvalStatusAt;
      return;
    }

    // else, it has not been manually set, so go through scores and see if they all pass
    const keys = this.scoreKeys;
    var counts = {PD:0, NR:0, OK:0};
    for(let i = 0; i < keys.length; i++) {
      counts[this[keys[i]].status]++;
    }

    if (counts.PD > 0) { // any pending means whole project is pending
      this.approvalStatus = 'PD';
    } else if (counts.NR > 0) { // any needs review, whole project needs review
      this.approvalStatus = 'NR';
    } else if (counts.OK == 5) { // only auto-approved if ALL scores pass
      this.approvalStatus = 'AP';
    }
  }

  _setScoreRules() {
    if ('projectScoreRules' in this.record) {
      this.scoreRules = this.record.projectScoreRules;
    } else {
      // TODO: report problem with Score Rules
    }
  }

  _setHasMissing() {
    const keys = this.scoreKeys;
    for(let i = 0; i < keys.length; i++) {
      if (this[keys[i]].missing.length > 0) {
        this.hasMissing = true;
        break;
      }
    }
  }

  /*
  The Debt Service Coverage Ratio is the (sum of Guarantor Free Monthly Cash Flow plus
  Operating Company Free Monthly Cash Flow) divided by the Project Total Monthly Principal & Interest**
  (**see 'Edit Project' > 'Financing Sources' tab. It’s the sum of Monthly Payment).
   */
  _calcDebtServiceCoverageRatio() {
    this.debtServiceCoverageRatio = { value:'N/A', score:'N/A', status:'PD', missing:[], info:[] }; // reset object
    this._setMissingDebtServiceCoverageRatio();

    if ( this.debtServiceCoverageRatio.missing.length > 0 ) {
      return;
    }

    var totMoFreeCashFlow = 0; // sum of Guarantor Free Monthly Cash Flow plus Operating Company Free Monthly Cash Flow

    for(let i = 0; i < this.record.guarantors.length; i++) {
      totMoFreeCashFlow += +this.record.guarantors[i].monthlyFreeCashFlow;
    }
    totMoFreeCashFlow += +this.record.ocMonthlyFreeCashFlow;

    var totMoPnI = this.getTotalMonthlyPrincipalAndInterest(); // Project Total Monthly Principal & Interest
    if (totMoPnI > 0) {
      this.debtServiceCoverageRatio.value = (totMoFreeCashFlow / totMoPnI).toFixed(1);

      // get scoreRules index of score that is set as approvalThreshold
      var apThIdx = this._getApprovalThresholdScoreRuleIndex('debtServiceCoverageRatio');

      // get score index for calculated value
      var scIdx = this._getScoreRuleIndexForGtEqValue('debtServiceCoverageRatio', 'minRatio', this.debtServiceCoverageRatio.value);
      this.debtServiceCoverageRatio.score = this.scoreRules.debtServiceCoverageRatio[scIdx].score;

      // for this score, if the srIdx >= apThIdx, then it's auto-approved
      this.debtServiceCoverageRatio.status = (scIdx >= apThIdx) ? 'OK' : 'NR';
    }
  }

  _setMissingDebtServiceCoverageRatio() {
    this.debtServiceCoverageRatio.missing = [];

    if ( ! ('guarantors' in this.record && this.record.guarantors.length > 0) ) {
      this.debtServiceCoverageRatio.missing.push('Guarantors');
    } else {
      for(let i = 0; i < this.record.guarantors.length; i++) {
        if ( ! ('monthlyFreeCashFlow' in this.record.guarantors[i] && this.record.guarantors[i].monthlyFreeCashFlow > 0) ) {
          this.debtServiceCoverageRatio.missing.push(this.record.guarantors[i].contact.name + ' mo. free cash flow');
        }
      }
    }

    if ( ! ('ocMonthlyFreeCashFlow' in this.record && +this.record.ocMonthlyFreeCashFlow > 0) ) {
      this.debtServiceCoverageRatio.missing.push('O.C. mo. free cash flow')
    }

    if ( ! ('projectFinancingSources' in this.record && this.record.projectFinancingSources.length > 0) ) {
      this.debtServiceCoverageRatio.missing.push('Project Financing Sources');
    } else { // check each Project Financing Source for principalAndInterestPayment
      for(let i = 0; i < this.record.projectFinancingSources.length; i++) {
        if (this.record.projectFinancingSources[i].financingSource.name === 'Personal') {
          continue;
        }
        if ( ! ('principalAndInterestPayment' in this.record.projectFinancingSources[i] && +this.record.projectFinancingSources[i].principalAndInterestPayment > 0) ) {
          this.debtServiceCoverageRatio.missing.push(this.record.projectFinancingSources[i].financingSource.name + ' P&I');
        }
      }
    }
  }

  /*
  The Balance Sheet Ratio is the sum of Operating Company Current and Long Term Assets divided by its
  Current and Long Term Liabilities
   */
  _calcBalanceSheetRatio() {
    this.balanceSheetRatio = { value:'N/A', score:'N/A', status:'PD', missing:[], info:[] }; // reset object

    // update missing fields, and bail if any are
    this._setMissingBalanceSheetRatio();
    if ( this.balanceSheetRatio.missing.length > 0 ) {
      return;
    }

    var totAssets = (+this.record.currentAssets + +this.record.longTermAssets);
    var totLiabilities = (+this.record.currentLiabilities + +this.record.longTermLiabilities);

    if (totLiabilities > 0) {
      this.balanceSheetRatio.value = (totAssets / totLiabilities).toFixed(1);

      // get scoreRules index of score that is set as approvalThreshold
      var apThIdx = this._getApprovalThresholdScoreRuleIndex('balanceSheetRatio');

      // get score index for calculated value
      var scIdx = this._getScoreRuleIndexForGtEqValue('balanceSheetRatio', 'minRatio', this.balanceSheetRatio.value);
      this.balanceSheetRatio.score = this.scoreRules.balanceSheetRatio[scIdx].score;

      // for this score, if the srIdx >= apThIdx, then it's auto-approved
      this.balanceSheetRatio.status = (scIdx >= apThIdx) ? 'OK' : 'NR';
    }
  }

  _setMissingBalanceSheetRatio() {
    this.balanceSheetRatio.missing = [];

    // not what is supposed to be > 0, so for now just check that the fields have some value present
    if ( ! ('currentAssets' in this.record) ) {
      this.balanceSheetRatio.missing.push('O.C. current assets');
    }
    if ( ! ('longTermAssets' in this.record) ) {
      this.balanceSheetRatio.missing.push('O.C. long term assets');
    }
    if ( ! ('currentLiabilities' in this.record) ) {
      this.balanceSheetRatio.missing.push('O.C. current liabilities');
    }
    if ( ! ('longTermLiabilities' in this.record) ) {
      this.balanceSheetRatio.missing.push('O.C. long term liabilities');
    }
  }

  /*
  This score is determined by the average FICO score of Guarantors.
   */
  _calcFicoCreditAverage() {
    this.ficoCreditAverage = { value:'N/A', score:'N/A', status:'PD', missing:[], info:[] }; // reset object

    // update missing fields, and bail if any are
    this._setMissingFicoCreditAverage();
    if ( this.ficoCreditAverage.missing.length > 0 ) {
      return;
    }

    var totFicoCredit = 0;
    for(let i = 0; i < this.record.guarantors.length; i++) {
      totFicoCredit += +this.record.guarantors[i].ficoScore;
    }

    if(this.record.guarantors.length > 0) {
      this.ficoCreditAverage.value = Math.round((totFicoCredit / this.record.guarantors.length));

      // get scoreRules index of score that is set as approvalThreshold
      var apThIdx = this._getApprovalThresholdScoreRuleIndex('ficoCreditAverage');

      // get score index for calculated value
      var scIdx = this._getScoreRuleIndexForGtEqValue('ficoCreditAverage', 'minAverage', this.ficoCreditAverage.value);
      this.ficoCreditAverage.score = this.scoreRules.ficoCreditAverage[scIdx].score;

      // for this score, if the srIdx >= apThIdx, then it's auto-approved
      this.ficoCreditAverage.status = (scIdx >= apThIdx) ? 'OK' : 'NR';
    }
  }

  _setMissingFicoCreditAverage() {
    this.ficoCreditAverage.missing = [];

    // first check for guarantor recs
    if (this.record.guarantors.length == 0) {
      this.ficoCreditAverage.missing.push('Guarantors');
      return;
    }

    // and if guarantors exist, ensure all have FICO value set
    for(let i = 0; i < this.record.guarantors.length; i++){
      if ( ! ('ficoScore' in this.record.guarantors[i] && +this.record.guarantors[i].ficoScore > 0) ) {
        this.ficoCreditAverage.missing.push('Guarantor ' + this.record.guarantors[i].contact.name + ' FICO score');
      }
    }
  }

  /*
  This score is determined by the percentage of the "Personal" Financing Source.
   */
  _calcLoanToValue() {
    this.loanToValue = { value:'N/A', score:'N/A', status:'PD', missing:[], info:[] }; // reset object

    // update missing fields, and bail if any are
    this._setMissingLoanToValue();
    if ( this.loanToValue.missing.length > 0 ) {
      return;
    }

    var percentage = null;
    for(let i = 0; i < this.record.projectFinancingSources.length; i++) {
      if (this.record.projectFinancingSources[i].financingSource.name === 'Personal') {
        this.personalFinancingSource = this.record.projectFinancingSources[i];
        percentage = this.record.projectFinancingSources[i].percentage;
      }
    }

    if (percentage) {
      this.loanToValue.value = percentage;

      // get scoreRules index of score that is set as approvalThreshold
      var apThIdx = this._getApprovalThresholdScoreRuleIndex('loanToValue');

      // get score index for calculated value
      var scIdx = this._getScoreRuleIndexForGtEqValue('loanToValue', 'minPercent', this.loanToValue.value);
      this.loanToValue.score = this.scoreRules.loanToValue[scIdx].score;

      // for this score, if the srIdx >= apThIdx, then it's auto-approved
      this.loanToValue.status = (scIdx >= apThIdx) ? 'OK' : 'NR';
    }
  }

  _setMissingLoanToValue() {
    var personalFound = false;
    for(let i = 0; i < this.record.projectFinancingSources.length; i++) {
      if (this.record.projectFinancingSources[i].financingSource.name === 'Personal') {
        personalFound = true;
        if ( ! ('percentage' in this.record.projectFinancingSources[i] && +this.record.projectFinancingSources[i].percentage > 0)) {
          this.loanToValue.missing.push('Personal financing source percentage');
          break;
        }
      }
    }

    // set missing if "Personal" financing source was not found
    if ( ! personalFound ) {
      this.loanToValue.missing.push('Personal financing source');
    }
  }

  /*
  This score will be determined by whether the Guarantors have tax liens and/or lawsuits, and if so, how many.
  I think for this one, the range will be the sum of liens and lawsuits for all Guarantors.
   */
  _calcGuarantorCharacter() {
    this.guarantorCharacter = { value:'N/A', score:'N/A', status:'PD', missing:[], info:[] }; // reset object

    // update missing fields, and bail if any are
    this._setMissingGuarantorCharacter();
    if ( this.guarantorCharacter.missing.length > 0 ) {
      return;
    }

    var totCount = 0;
    for(let i = 0; i < this.record.guarantors.length; i++) {
      if ('taxLienCount' in this.record.guarantors[i]) {
        totCount += +this.record.guarantors[i].taxLienCount;
      }
      if ('lawsuitCount' in this.record.guarantors[i]) {
        totCount += +this.record.guarantors[i].lawsuitCount;
      }
      if ('bankruptcyCount' in this.record.guarantors[i]) {
        totCount += +this.record.guarantors[i].bankruptcyCount;
      }
    }

    this.guarantorCharacter.value = totCount;

    // get scoreRules index of score that is set as approvalThreshold
    var apThIdx = this._getApprovalThresholdScoreRuleIndex('guarantorCharacter');

    // get score index for calculated value
    var scIdx = this._getScoreRuleIndexForGtEqValue('guarantorCharacter', 'minCount', this.guarantorCharacter.value);
    this.guarantorCharacter.score = this.scoreRules.guarantorCharacter[scIdx].score;

    // for this score, if the srIdx >= apThIdx, then it's auto-approved
    this.guarantorCharacter.status = (scIdx <= apThIdx) ? 'OK' : 'NR'; // the threshold is for values less than or equal to the minCount
  }

  _setMissingGuarantorCharacter() {
    if (this.record.guarantors.length == 0) {
      this.guarantorCharacter.missing.push('Guarantors');
    }
  }

/*
PRIVATE UTIL METHODS
*/
  _getApprovalThresholdScoreRuleIndex(key) {
    for (let idx = 0; idx < this.scoreRules[key].length; idx++) {
      if (this.scoreRules[key][idx].isApprovalThreshold === true) {
        return idx;
      }
    }
  }

  // returns scoreRule index for a value where the value is >= min value.
  _getScoreRuleIndexForGtEqValue(key, minField, value) {
    for (let idx = 4; idx >= 0; idx--) {
      if (value >= this.scoreRules[key][idx][minField]) {
        return idx;
      }
    }
  }


/*
PUBLIC METHODS
*/
  getDebtServiceCoverageRatio(key) {
    if (key in this.debtServiceCoverageRatio) {
      return this.debtServiceCoverageRatio[key];
    } else { // no key or invalid key, return the value
      return this.debtServiceCoverageRatio.value;
    }
  }

  getDebtServiceCoverageRatioStatus() {
    return "TODO: debt service coverage ratio";
  }

  getBalanceSheetRatio(key) {
    if (key in this.balanceSheetRatio) {
      return this.balanceSheetRatio[key];
    } else { // no key or invalid key, return the value
      return this.balanceSheetRatio.value;
    }
  }

  getFicoCreditAverage(key) {
    if (key in this.ficoCreditAverage) {
      return this.ficoCreditAverage[key];
    } else { // no key or invalid key, return the value
      return this.ficoCreditAverage.value;
    }
  }

  getLoanToValue(key) {
    if (key in this.loanToValue) {
      return this.loanToValue[key];
    } else { // no key or invalid key, return the value
      return this.loanToValue.value;
    }
  }

  getGuarantorCharacter(key) {
    if (key in this.guarantorCharacter) {
      return this.guarantorCharacter[key];
    } else { // no key or invalid key, return the value
      return this.guarantorCharacter.value;
    }
  }

  getApprovalStatus(key) {
    if (this[key]) {
      return this[key];
    } else {
      return this.approvalStatus;
    }
  }

  getApprovalStatusDisplay() {
    if (this.approvalStatus in this.apprStatCodes) {
      return this.apprStatCodes[this.approvalStatus].display;
    }
  }

  getApprovalStatusBy() {
    if (this.approvalStatusBy) {
      return this.approvalStatusBy.name;
    }
    return 'N/A';
  }

  getApprovalStatusAt() {
    if (this.approvalStatusAt) {
      return new Date(this.record.approvalStatusAtUTC).toLocaleString();
    }
    return 'N/A';
  }

  getTotalMonthlyPrincipalAndInterest() {
    var totMoPnI = 0;
    for(let i = 0; i < this.record.projectFinancingSources.length; i++) {
      if (this.record.projectFinancingSources[i].financingSource.name === 'Personal') {
        continue;
      }
      if ('principalAndInterestPayment' in this.record.projectFinancingSources[i]) {
        totMoPnI += +this.record.projectFinancingSources[i].principalAndInterestPayment;
      }
    }

    return totMoPnI;
  }

  getPersonalFinancingSource() {
    return this.personalFinancingSource;
  }
}