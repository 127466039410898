import React from 'react';
import {
  List,
  Datagrid,
  TextField,
  BooleanField,
  EditButton
} from 'react-admin';
import { Grid, Typography } from '@material-ui/core';

export const LoanToValueScoreList = props => {

  return (
    <div className="scoreRules">
      <div className="listInfo">
        <Grid container direction="row" alignItems="center" spacing={6}>
          <Grid item xs={12}>
            <Typography variant="h4" color="secondary" className="title">
              Loan to Value Scores
            </Typography>
          </Grid>
        </Grid>
      </div>
      <List {...props} actions={false} bulkActionButtons={false}>
        <Datagrid>
          <TextField className="row" source="score" />
          <TextField className="row" source="minPercent" label="Min Percent (=>)" />
          <BooleanField className="row" source="isApprovalThreshold" label="Approval Threshold" />
          <EditButton />
        </Datagrid>
      </List>
    </div>
  );
};
