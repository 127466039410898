import React from 'react';
import { ResourceGuesser } from '@api-platform/admin';
import { FicoCreditAverageScoreList } from "./FicoCreditAverageScoreList";
import { FicoCreditAverageScoreEdit } from "./FicoCreditAverageScoreEdit";

export default (
  <ResourceGuesser
    name="fico_credit_average_scores"
    list={FicoCreditAverageScoreList}
    show={false}
    edit={FicoCreditAverageScoreEdit}
    create={false}
  />
);