import * as React from "react";
import { useMutation, Button, showNotification, useNotify } from 'react-admin';
import { getCurrentUserId } from '../authProvider';

const ApprovalStatusButton = ({ record, statusCode }) => {
  const notify = useNotify();
  //console.log('ApproveButton Props', record);
  //console.log('userid?', getCurrentUserId());
  //console.log('toIsoString()', new Date().toISOString());

  const [approve, { loading, error, loaded }] = useMutation({
    type: 'update',
    resource: 'projects',
    payload: { id: record.id, data: { approvalStatus:statusCode, approvalStatusBy:'/api/users/'+getCurrentUserId(), approvalStatusAt:new Date().toISOString() } }
  });
  if (loaded) { notify('Approval status successfully updated', 'success') };
  if (error) { notify('ERROR: Server communication error', 'error') };
  const btnLabel = (statusCode == 'AP') ? 'Approve' : (statusCode == 'NR') ? 'Review' : 'Reject';
  return <Button className={'btnApprStat'+statusCode} label={btnLabel} variant="contained" onClick={approve} disabled={loading} />;
};

export default ApprovalStatusButton;

/*
const ApproveButton = () => {
  //const dataProvider = useDataProvider();
  //console.log('dataProvider', dataProvider);
  return (
    <Button variant="contained" className="btnApprove" onClick={(event) => {console.log('clicked2')}}>Approve</Button>
  )
};

export default ApproveButton;


class ApproveButtonXXX extends Component {
  constructor(props) {
    super(props);
    console.log('ApproveButton props', props);
  }

  handleClick = () => {
    const { dataProvider, dispatch, record } = this.props;
    const updatedRecord = { ...record, is_approved: true };
    dataProvider(UPDATE, 'comments', { id: record.id, data: updatedRecord })
      .then(() => {
        dispatch(showNotification('Comment approved'));
        //dispatch(push('/comments'));
      })
      .catch((e) => {
        dispatch(showNotification('Error: comment not approved', 'warning'))
      });
  }

  render() {
    return <Button label="Approve" onClick={this.handleClick} />;
  }
}

ApproveButtonXXX.propTypes = {
  dataProvider: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  record: PropTypes.object,
};

//export default withDataProvider(ApproveButton)

 */