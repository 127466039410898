import { TextInput, required } from 'react-admin';
import React from 'react';
import { Grid } from '@material-ui/core';

const ProjectNoteForm = () => (
  <>
    <Grid item xs={12}>
      <TextInput
        validate={[required()]}
        fullWidth
        label="Note"
        source="note"
        style={{ width: '100%' }}
      />
    </Grid>
  </>
);

export default ProjectNoteForm;
