import React, { useState, useEffect } from 'react';
import { SimpleShowLayout, Show, useQuery } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import {
  Grid,
  Typography,
  TableContainer,
  Table,
  TableCell,
  TableHead,
  TableRow,
  TableBody,
  Paper
} from '@material-ui/core';
import { getCurrentUserId, getUsername, getToken } from '../authProvider';
import { currencyFormat } from '../../util';
import { LoadingPage } from 'ra-ui-materialui';
import jsPDF from "jspdf";
import * as html2pdf from 'html2pdf.js';
import ProjectScore from '../project_score/ProjectScore.js';

const useStyles = makeStyles(theme => ({
  root: {
    width: '90%',
    display: 'flex',
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  gridItem: {
    width: '15%'
  },
  nestedGridItem: {
    width: '95%'
  },
  addButton: {
    width: '5%'
  },
  selectItem: {
    width: '90%'
  },
  cellbg: {
    backgroundColor: '#eee'
  }
}));

const ProjectShowInfo = ({ record }) => {
  const classes = useStyles();

  let totalFinancingAmount = 0;
  let totalMonthlyPAndI = 0;
  if (record.projectFinancingSources) {
    record.projectFinancingSources.forEach(source => {
      if (source['amount']) {
        totalFinancingAmount += parseFloat(source['amount']);
      }
      if (source['principalAndInterestPayment']) {
        totalMonthlyPAndI += parseFloat(source['principalAndInterestPayment']);
      }
    });
  }
  let debtServiceRatiosStr = '';
  let debtServiceRatiosYearStr = '';
  if (record.debtServiceRatio) {
    for (var i = 0; i < record.debtServiceRatio.length; i++) {
      if (i < record.debtServiceRatio.length - 1) {
        debtServiceRatiosStr += record.debtServiceRatio[i].ratio + ', ';
        debtServiceRatiosYearStr += record.debtServiceRatio[i].year + ', ';
      } else {
        debtServiceRatiosStr += 'and ' + record.debtServiceRatio[i].ratio;
        debtServiceRatiosYearStr += 'and ' + record.debtServiceRatio[i].year;
      }
    }
  }

  return (
    <>
      <button
        onClick={event => exportPdfProjectReport(record, event)}
        className="btn btn-info btn-yellow pull-left export-pdf-button"
      >
        Export PDF
      </button>

      <div id="repCont">
        <h1>Loan Summary</h1>

        <b>Date: {new Date().toDateString()}</b>
        <p>
          <b>
            Operating Company:{' '}
            {record.operatingCompany && record.operatingCompany.name}
          </b>
        </p>

        <p>Prepared by: {record.preparedByUserName || ''}</p>

        <p>
          <b>Project collateral property:</b> {record.streetAddress} {record.city}
          , {record.state} {record.postalCode} {record.country}
        </p>

        <div>
          <b>Project Costs</b>
          <ul>
            <li>
              Total project costs are{' '}
              {currencyFormat(parseFloat(record.purchaseLandAndBuilding) +
                parseFloat(record.tenantImprovement) +
                parseFloat(record.eligibleFees))}
              ; Purchased Land and Building {currencyFormat(record.purchaseLandAndBuilding)}.
            </li>
            {record.generalDescription && <li>{record.generalDescription}</li>}
          </ul>
        </div>

        <br />
        <h3>Financing</h3>

        <TableContainer component={Paper}>
          <Table aria-label="simple table">
            <TableHead className={classes.cellbg}>
              <TableRow>
                <TableCell>Source</TableCell>
                <TableCell align="right">Amount</TableCell>
                <TableCell align="right">Term</TableCell>
                <TableCell align="right">%</TableCell>
                <TableCell align="right">P&amp;I</TableCell>
                <TableCell align="right">Amort</TableCell>
                <TableCell align="right">Rate</TableCell>
                <TableCell align="right">Lien</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {record.projectFinancingSources &&
                record.projectFinancingSources.map(row => (
                  <TableRow key={row.financingSource.name}>
                    <TableCell component="th" scope="row">
                      {row.financingSource.name}
                    </TableCell>
                    <TableCell align="right">
                      {row.amount ? currencyFormat(row.amount) : ''}
                    </TableCell>
                    <TableCell align="right">{row.term}</TableCell>
                    <TableCell align="right">{row.percentage}%</TableCell>
                    <TableCell align="right">
                      {row.principalAndInterestPayment
                        ? currencyFormat(row.principalAndInterestPayment)
                        : ''}
                    </TableCell>
                    <TableCell align="right">
                      {row.amortization ? row.amortization : ''}
                    </TableCell>
                    <TableCell align="right">
                      {row.rate ? row.rate + '%' : ''}
                    </TableCell>
                    <TableCell align="right">
                      {row.lienPosition ? row.lienPosition.position : ''}
                    </TableCell>
                  </TableRow>
                ))}
              <tr>
                <TableCell className={classes.cellbg}>
                  <b>Total Financing:</b>
                </TableCell>
                <TableCell align="right">
                  <b>{currencyFormat(totalFinancingAmount)}</b>
                </TableCell>
                <TableCell align="right" className={classes.cellbg} colSpan={2}>
                  <b>Total Monthly P&I Payment</b>
                </TableCell>
                <TableCell align="right">
                  <b>{currencyFormat(totalMonthlyPAndI)}</b>
                </TableCell>
                <TableCell align="right" className={classes.cellbg} colSpan={2}>
                  <b>Total Annualized Payments</b>
                </TableCell>
                <TableCell align="right">
                  <b>{currencyFormat(totalMonthlyPAndI * 12)}</b>
                </TableCell>
              </tr>
              <tr>
                <TableCell>
                  <b>Prepayment Penalty</b>
                </TableCell>
                <TableCell colSpan={7}>
                  {record.paymentPenalty ? 'Yes' : 'no'}
                </TableCell>
              </tr>
              <tr>
                <TableCell className={classes.cellbg}>
                  <b>Interim Lender</b>
                </TableCell>
                <TableCell colSpan={7}>
                  {record.interimLender ? record.interimLender.name : 'n/a'}
                </TableCell>
              </tr>
              <tr>
                <TableCell className={classes.cellbg} colSpan={8}>
                  The CDC Interest Rate in the Financing Chart represents the
                  coupon rate on the note. The SBA monthly P&I payment is
                  calculated based on the "all-in" rate (coupon plus other fees)
                  for the first 5 year period.
                </TableCell>
              </tr>
            </TableBody>
          </Table>
        </TableContainer>

        <div class="pageBreak"></div>
        <ProjectScore record={record} showActions={false} />

        <div className="pageBreak"></div>
        <br />
        {record.borrower &&
          record.borrower.map((b, i) => {
            if ('company' in b) {
              let contactItems = [];
              b.borrowerCompanyOwnership.forEach((bco, j) => {
                contactItems.push(
                  <li key={j}>
                    {bco.ownershipPercent}% owned by {bco.contact.name}
                  </li>
                );
              });

              return (
                <div key={i}>
                  <BorrowerLabel label={b.company.name} />
                  <ul>{contactItems}</ul>
                  <br />
                </div>
              );
            } else {
              return (
                <div key={i}>
                  <BorrowerLabel label={b.contact.name} />
                  <br />
                  <br />
                </div>
              );
            }
          })}

        <hr />

        <p>
          <b>OC: {record.operatingCompany && record.operatingCompany.name}</b>
        </p>
        <ul>
          {record.projectOperatingCompanyOwnerships &&
            record.projectOperatingCompanyOwnerships.map((p, i) => {
              return (
                <li key={i}>
                  {p.ownershipPercentage}% owned by {p.contact.name}
                </li>
              );
            })}
          {record.ocGeneralDescription && <li>{record.ocGeneralDescription}</li>}
        </ul>
        <p>
          <b>Historical Cash Flows</b>
        </p>
        <ul>
          <li>
            As of {new Date(record.periodEndingDate).toLocaleDateString('en-US')},
            the OC has {record.currentAssets ? '$' + record.currentAssets : 'n/a'} and{' '}
            {record.longTermAssets ? '$' + record.longTermAssets : 'n/a'}.
          </li>
          {debtServiceRatiosStr && debtServiceRatiosYearStr && (
            <li>
              DCR is {debtServiceRatiosStr} for {debtServiceRatiosYearStr}
            </li>
          )}
        </ul>

        {record.guarantors && record.guarantors.length > 0 && (
          <>
            <p>
              <b>Guarantors</b>
            </p>
          </>
        )}

        {record.guarantors &&
          record.guarantors.map((g, i) => {
            return (
              <p key={i}>
                {g.contact.name} -- FICO Score {g.ficoScore}
              </p>
            );
          })}

        <p>
          SBA Appraisal Approval: {record.sbaAppraisalApproval ? 'yes' : 'no'}
        </p>
        {record.sbaAuthorizationNumber && (
          <p>SBA Authorization #:{record.sbaAuthorizationNumber}</p>
        )}
        <p>
          Environmental Approval: {record.environmentalApproval ? 'yes' : 'no'}
        </p>
      </div>
    </>
  );
};

const BorrowerLabel = props => {
  return <b>Borrower/EPC: {props.label}</b>;
};

export const ProjectsShow = props => (
  <Show {...props}>
    <SimpleShowLayout>
      <ProjectShowInfo />
    </SimpleShowLayout>
  </Show>
);

export const ProjectPdfContent = props => {
  // try this pulling the record itself getting the ID from the record
  const projectId = decodeURIComponent(props.match.params.id);
  const [record, setRecord] = useState({});

  useEffect(() => {
    if (projectId && !record.operatingCompany) {
      const entrypoint = process.env.REACT_APP_API_ENTRYPOINT;
      let headers = new Headers();
      headers.set('Authorization', 'Bearer ' + getToken());

      fetch(entrypoint + projectId.replace('/api', ''), {
        method: 'GET',
        headers
      })
        .then(response => {
          return response.json();
        })
        .then(record => {
          setRecord(record);
        });
    }
  }, [record]);

  return <ProjectShowInfo {...props} record={record} />;
};

export const PdfLoading = props => {
  const matches = window.location.pathname.match(`^/projects/(.*)/pdf$`);
  const projectId = matches && matches.length > 0 ? matches[1] : '';
  if (projectId) {
    return (
      <ProjectPdfContent
        match={{
          params: {
            id: projectId
          }
        }}
      />
    );
  }
  return <LoadingPage />;
};

function exportPdfProjectReport(record) {
  // with html2pdf, total pdf width = 790px
  const oHtml = document.querySelector('#repCont').cloneNode(true);
  let origHtml = oHtml.innerHTML;
  let strCSS = `
<style>
#repCont {font-size:11px;padding:0 20px 20px 20px}
#repCont {width:750px}
#repCont th, #repCont .MuiTableRow-head > th {font-size:11px}
#repCont td {font-size:11px}
#repCont .MuiTableCell-root {padding:3px;font-size:11px}
#repCont .pageBreak {page-break-before:always}
</style>
`;
  oHtml.innerHTML = strCSS + origHtml;
  html2pdf(oHtml);
}

// JSPDFV: jsPDF VERSION
function exportPdfProjectReport_JSPDFV(record) {
  var doc = new jsPDF("l", "pt", "a4");
  var oHtml = document.querySelector('#repCont');
  let origHTML = oHtml.innerHTML;
  let strCSS = `
<style>
#repCont {font-size:10px;padding:5px}
#repCont {border:2px solid red;width:610px}
#repCont th, #repCont .MuiTableRow-head > th {font-size:10px}
#repCont td {font-size:10px}
#repCont .MuiTableCell-root {padding:3px;font-size:10px}
#repCont .pageBreak {page-break-before:always}
</style>
`;
  oHtml.innerHTML = strCSS + origHTML;

  doc.html(oHtml, {
    callback: function(pdf) {
      pdf.save("test_pdf.pdf");
      //oHtml.innerHTML = origHTML;
    }
  });
}

// ACV: API CALL VERSION
export function exportPdfProjectReport_ACV(item) {
  const entrypoint = process.env.REACT_APP_API_ENTRYPOINT;
  let headers = new Headers();
  headers.set('Accept', 'application/pdf');
  headers.set('Content-Type', 'application/pdf');
  headers.set('Authorization', 'Bearer ' + getToken());

  return (
    fetch(entrypoint + item['@id'].replace('/api', '') + '/export/pdf', {
      method: 'POST',
      headers
    })
      // https://medium.com/yellowcode/download-api-files-with-react-fetch-393e4dae0d9e
      // 1. Convert the data into 'blob'
      .then(response => response.blob())
      .then(blob => {
        // 2. Create blob link to download
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute(
          'download',
          `project-report-${item['@id'].replace('/api/projects/', '')}.pdf`
        );
        // 3. Append to html page
        document.body.appendChild(link);
        // 4. Force download
        link.click();
        // 5. Clean up and remove the link
        link.parentNode.removeChild(link);
      })
      .catch(e => {})
  );
}
