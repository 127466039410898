import React from 'react';
import {
    TableContainer,
    Table,
    TableCell,
    TableHead,
    TableRow,
    TableBody,
    Paper
} from '@material-ui/core';
import { currencyFormat } from "../../util/genUtils";
import './_project_score.css';
import { ProjectScoreCalc } from "./ProjectScoreCalc.class.js";
import ApprovalStatusButton from "./ProjectScoreActionButtons";

const ProjectScore = (props) => {
    const record = props.record;
    //console.log('record', record);
    const ocMonthlyFreeCashFlow = record.ocMonthlyFreeCashFlow ? currencyFormat(record.ocMonthlyFreeCashFlow) : currencyFormat(0);
    const currentAssets = record.currentAssets ? currencyFormat(record.currentAssets) : currencyFormat(0);
    const currentLiabilities = record.currentLiabilities ? currencyFormat(record.currentLiabilities) : currencyFormat(0);
    const longTermAssets = record.longTermAssets ? currencyFormat(record.longTermAssets) : currencyFormat(0);
    const longTermLiabilities = record.longTermLiabilities ? currencyFormat(record.longTermLiabilities) : currencyFormat(0);
    const totalAssets = currencyFormat((record.currentAssets ? +record.currentAssets : 0) + (record.longTermAssets ? +record.longTermAssets : 0));
    const totalLiabilities = currencyFormat((record.currentLiabilities ? +record.currentLiabilities : 0) + (record.longTermLiabilities ? +record.longTermLiabilities : 0));
    const PSC = new ProjectScoreCalc(record);
    const personalFinancingSource = PSC.getPersonalFinancingSource();

    return (
        <>
            <h3>Project Approval</h3>
            <TableContainer component={Paper} className="paTbl">
                <Table aria-label="simple table">
                    <TableBody>
                        <TableRow>
                            <TableCell component="th" scope="row">
                                Current Status:
                            </TableCell>
                            <TableCell className={"colA " + PSC.getApprovalStatus()}>
                                {PSC.getApprovalStatusDisplay()}
                            </TableCell>
                            <TableCell component="th" scope="row">
                                Details:
                            </TableCell>
                            <TableCell className="colB">
                                Set by: {PSC.getApprovalStatusBy()}<br />
                                Set at: {PSC.getApprovalStatusAt()}
                            </TableCell>
                            {props.showActions &&
                                <>
                                    <TableCell component="th" scope="row">
                                        Actions:
                                    </TableCell>
                                    <TableCell className="colC">
                                            <ApprovalStatusButton record={record} statusCode="AP" />
                                            <ApprovalStatusButton record={record} statusCode="NR" />
                                            <ApprovalStatusButton record={record} statusCode="RJ" />
                                    </TableCell>
                                </>
                            }
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>

            <h3>Project Scores</h3>
            <TableContainer component={Paper} className="psTbl">
                <Table aria-label="simple table">
                    <TableBody>
                        <TableRow>
                            <TableCell></TableCell>
                            <TableCell component="th">Debt Service Coverage</TableCell>
                            <TableCell component="th">Balance Sheet</TableCell>
                            <TableCell component="th">FICO Credit Average</TableCell>
                            <TableCell component="th">Loan to Value</TableCell>
                            <TableCell component="th">Guarantor Character</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell className="lftLbl">Value</TableCell>
                            <TableCell>{PSC.getDebtServiceCoverageRatio()}</TableCell>
                            <TableCell>{PSC.getBalanceSheetRatio()}</TableCell>
                            <TableCell>{PSC.getFicoCreditAverage()}</TableCell>
                            <TableCell>{PSC.getLoanToValue()}</TableCell>
                            <TableCell>{PSC.getGuarantorCharacter()}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell className="lftLbl">Score</TableCell>
                            <TableCell><span className={PSC.getDebtServiceCoverageRatio('status')}>{PSC.getDebtServiceCoverageRatio('score')}</span></TableCell>
                            <TableCell><span className={PSC.getBalanceSheetRatio('status')}>{PSC.getBalanceSheetRatio('score')}</span></TableCell>
                            <TableCell><span className={PSC.getFicoCreditAverage('status')}>{PSC.getFicoCreditAverage('score')}</span></TableCell>
                            <TableCell><span className={PSC.getLoanToValue('status')}>{PSC.getLoanToValue('score')}</span></TableCell>
                            <TableCell><span className={PSC.getGuarantorCharacter('status')}>{PSC.getGuarantorCharacter('score')}</span></TableCell>
                        </TableRow>
                        {PSC.hasMissing &&
                          <TableRow>
                            <TableCell className="lftLbl">Missing</TableCell>
                            <TableCell className="tdMissing">{PSC.getDebtServiceCoverageRatio('missing').join(' | ')}</TableCell>
                            <TableCell className="tdMissing">{PSC.getBalanceSheetRatio('missing').join(' | ')}</TableCell>
                            <TableCell className="tdMissing">{PSC.getFicoCreditAverage('missing').join(' | ')}</TableCell>
                            <TableCell className="tdMissing">{PSC.getLoanToValue('missing').join(' | ')}</TableCell>
                            <TableCell className="tdMissing">{PSC.getGuarantorCharacter('missing').join(' | ')}</TableCell>
                          </TableRow>
                        }
                    </TableBody>
                </Table>
            </TableContainer>

            <h3>Monthly Principal & Interest</h3>
            <TableContainer component={Paper} className="pniTbl">
                <Table aria-label="simple table">
                    <TableBody>
                        {record.projectFinancingSources &&
                        record.projectFinancingSources.map(row => (
                          row.financingSource.name !== 'Personal' &&
                            <TableRow>
                                <TableCell component="th" scope="row">
                                    {row.financingSource.name}
                                </TableCell>
                                <TableCell align="right">
                                    {currencyFormat(row.principalAndInterestPayment)}
                                </TableCell>
                            </TableRow>
                        ))}
                        <TableRow>
                          <TableCell>TOTAL</TableCell>
                          <TableCell align="right">{currencyFormat(PSC.getTotalMonthlyPrincipalAndInterest())}</TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>

            <h3>Guarantors</h3>
            <TableContainer component={Paper} className="guTbl">
                <Table aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Name</TableCell>
                            <TableCell align="right">Mo. Free<br />Cash Flow</TableCell>
                            <TableCell align="right">FICO<br />Score</TableCell>
                            <TableCell align="right">Tax Liens</TableCell>
                            <TableCell align="right">Lawsuits</TableCell>
                            <TableCell align="right">Bankruptcies</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {record.guarantors &&
                        record.guarantors.map(row => (
                          <TableRow key={row['@id']}>
                              <TableCell component="th" scope="row">
                                  {row.contact.name}
                              </TableCell>
                              <TableCell align="right">
                                  {row.monthlyFreeCashFlow ? currencyFormat(row.monthlyFreeCashFlow) : currencyFormat(0)}
                                  {row.mfcfProjected &&
                                    <div style={{fontSize:"11px"}}>projected</div>
                                  }
                              </TableCell>
                              <TableCell align="right">
                                  {row.ficoScore ? row.ficoScore : ''}
                              </TableCell>
                              <TableCell align="right">
                                  {row.taxLienCount ? row.taxLienCount : 0}
                              </TableCell>
                              <TableCell align="right">
                                  {row.lawsuitCount ? row.lawsuitCount : 0}
                              </TableCell>
                              <TableCell align="right">
                                  {row.bankruptcyCount ? row.bankruptcyCount : 0}
                              </TableCell>
                          </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

            <h3>Operating Company</h3>
            <div className="ocCon">
                <div className="flx-con">
                    <div>
                        <b>Mo. Free Cash Flow</b>
                        <div>
                            {ocMonthlyFreeCashFlow}
                            {record.ocMfcfProjected &&
                            <div style={{fontSize:"11px"}}>projected</div>
                            }
                        </div>
                    </div>
                    <div>
                        <b>Personal Financing Source</b>
                        {personalFinancingSource ?
                            <Table aria-label="simple table" className="perFinSrc">
                                <TableBody>
                                    <TableRow>
                                        <TableCell>
                                            <div>Amount</div>
                                            {currencyFormat(personalFinancingSource.amount)}
                                        </TableCell>
                                        {/* remove if Personal Financing Source never has "Rate" set
                                        <TableCell>
                                            <div>Rate</div>
                                            {personalFinancingSource.rate}
                                        </TableCell>
                                        */}
                                        <TableCell>
                                            <div>Percent</div>
                                            {personalFinancingSource.percentage}%
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                          :
                            <div>N/A</div>
                        }
                    </div>
                </div>

                <div className="cred-comms">
                    <b>Credit Comments</b><br />
                    <textarea
                        value={record.creditComments}
                        readOnly
                    />
                </div>
            </div>

            <TableContainer component={Paper} className="ocTbl">
                <Table aria-label="simple table">
                    <TableBody>
                        <TableRow>
                            <TableCell component="th" scope="row">
                                Current Assets
                            </TableCell>
                            <TableCell align="right">
                                {currentAssets}
                            </TableCell>
                            <TableCell component="th" scope="row">
                                Current Liabilities
                            </TableCell>
                            <TableCell align="right">
                                {currentLiabilities}
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell component="th" scope="row">
                                Long Term Assets
                            </TableCell>
                            <TableCell align="right">
                                {longTermAssets}
                            </TableCell>
                            <TableCell component="th" scope="row">
                                Long Term Liabilities
                            </TableCell>
                            <TableCell align="right">
                                {longTermLiabilities}
                            </TableCell>
                        </TableRow>
                        <TableRow className="sumRow">
                            <TableCell component="th" scope="row">
                                Total Assets
                            </TableCell>
                            <TableCell align="right">
                                {totalAssets}
                            </TableCell>
                            <TableCell component="th" scope="row">
                                Total Liabilities
                            </TableCell>
                            <TableCell align="right">
                                {totalLiabilities}
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    );
}

export default ProjectScore;
