import React from 'react';
import { TextInput } from 'react-admin';
import { currencyFormat } from '../../util';

class AssetsFormFields extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            total: 0,
            intangible: 0,
            curr: 0,
            longTerm: 0
        }
        this.handleFieldChange = this.handleFieldChange.bind(this);

        this.intangibleAssetsRef = React.createRef();
        this.currentAssetsRef = React.createRef();
        this.longTermAssetsRef = React.createRef();
    }

    updateTotal() {
        const intangible = isNaN(parseFloat(this.intangibleAssetsRef.current.value)) ? 0 : parseFloat(this.intangibleAssetsRef.current.value);
        const current = isNaN(parseFloat(this.currentAssetsRef.current.value)) ? 0 : parseFloat(this.currentAssetsRef.current.value);
        const longTerm = isNaN(parseFloat(this.longTermAssetsRef.current.value)) ? 0 : parseFloat(this.longTermAssetsRef.current.value);

        this.setState({
            intangible: intangible,
            curr: current,
            longTerm: longTerm,
            total: currencyFormat(intangible + current + longTerm),
            scoringTotal: currencyFormat(current + longTerm)
        });
    }

    componentDidMount() {
        this.updateTotal();
    }

    handleFieldChange() {
        this.updateTotal();
    }

    render() {
        return (
            <div>
                <TextInput
                  className="muiAlignRight"
                  fullWidth
                  label="Intangible Assets"
                  source="intangibleAssets"
                  inputRef={this.intangibleAssetsRef}
                  onChange={this.handleFieldChange}
                />
                <TextInput
                    className="muiAlignRight"
                    fullWidth
                    label="Current Assets"
                    source="currentAssets"
                    inputRef={this.currentAssetsRef}
                    onChange={this.handleFieldChange}
                />
                <TextInput
                    className="muiAlignRight"
                    fullWidth
                    label="Long Term Assets"
                    source="longTermAssets"
                    inputRef={this.longTermAssetsRef}
                    onChange={this.handleFieldChange}
                />
                <div className="alignRight" style={{paddingRight:'10px'}}>TOTAL: {this.state.total}</div>
                <div className="alignRight" style={{paddingRight:'10px'}}>SCORING TOTAL: {this.state.scoringTotal}</div>
            </div>
        );
    }
}

export default AssetsFormFields;