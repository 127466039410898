import React from 'react';
import { FieldGuesser } from '@api-platform/admin';
import {
  TextInput,
  AutocompleteInput,
  Datagrid,
  Edit,
  TabbedForm,
  FormTab,
  ReferenceInput,
  ReferenceManyField,
  DateInput,
  BooleanInput,
  DeleteButton,
  FunctionField,
  TextField,
  DateField,
  SelectInput
} from 'react-admin';
import CompanyFormField from '../companies/CompanyFormField';
import BorrowerFormField from '../borrowers/BorrowerFormField';
import BorrowerEditPanel from '../borrowers/BorrowerEditPanel';
import ProjectFinancingSourceFormFields from '../project_financing_sources/ProjectFinancingSourceFormFields';
import PostPanel from '../project_financing_sources/ProjectFinancingSourcePanel';
import ModalCreateButton from '../helpers/ModalCreateButton';
import ModalCreateButtonWithForm from '../helpers/ModalCreateButtonWithForm';
import { EditActions } from '../actions/EditActions';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import ModalEditButton from '../helpers/ModalEditButton';
import ProjectOperatingCompanyFormFields from '../project_operating_company_ownership/ProjectOperatingCompanyFormFields';
import GuarantorFormField from '../guarantors/GuarantorFormField';
import ProjectFinancingSourceSummary from '../project_financing_sources/ProjectFinancingSourceSummary';
import FinancingSourceFormField from '../financing_sources/FinancingSourceFormField';
import ProjectFinancingSourceAmortization from '../project_financing_sources/ProjectFinancingSourceAmortization';
import ProjectNoteForm from '../project_notes/ProjectNoteForm';
import ProjectNotesExportButton from '../project_notes/ProjectNotesExportButton';
import AssetsFormFields from "../oc_info/AssetsFormFields";
import LiabilitiesFormFields from "../oc_info/LiabilitiesFormFields";
import ProjectScore from '../project_score/ProjectScore.js';
import { ProjectScoreCalc } from "../project_score/ProjectScoreCalc.class.js";
import { NoBasePathGrid } from "../helpers/WidgetCollection";
import './_project_edit.css';

const useStyles = makeStyles(theme => ({
  root: {
    width: '90%',
    display: 'flex',
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  rootNoFlex: {
    width: '90%',
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  gridItem: {
    width: '15%'
  },
  nestedGridItem: {
    width: '95%'
  },
  addButton: {
    width: '5%'
  },
  selectItem: {
    width: '90%'
  }
}));

const ProjectTitle = ({ record }) => {
  return 'Edit - Project';
};

export const ProjectsEdit = props => {
  const classes = useStyles();

  // called when user hits [SAVE], allows transforming of submitted data before sending to server.
  const editTransform = (data) => {
    var PSC = new ProjectScoreCalc(data);
    // only auto-set if the user has not manually set (noted by presence of a value in the 'approvalStatusBy' field)
    // also note, it is only auto-set here, and the only place it can be manually set is on the Score Tab.
    if ( ! data.approvalStatusBy) {
      if (data.approvalStatus != PSC.getApprovalStatus()) {
        data.approvalStatus = PSC.getApprovalStatus();
      }
    }
    return data;
  };

  return (
    <Edit {...props} actions={<EditActions title={<ProjectTitle />} />} transform={editTransform} className="projectsEdit">
      <TabbedForm redirect={props.location.pathname}>
        <FormTab label={'General Info'}>
          <>
            <div className={classes.root}>
              <Grid container direction="row" alignItems="center" spacing={6}>
                <Grid item className={classes.gridItem} xs={12} sm={6} lg={4}>
                  <Grid
                    container
                    direction="row"
                    alignItems="center"
                    spacing={0}
                  >
                    <Grid item className={classes.nestedGridItem}>
                      <ReferenceInput
                        source="operatingCompany"
                        reference="companies"
                        label="Operating Company"
                        filterToQuery={searchText => ({ title: searchText })}
                        format={v => {
                          return v instanceof Object ? v['@id'] : v;
                        }}
                        fullWidth
                      >
                        <AutocompleteInput
                          optionText="name"
                        />
                      </ReferenceInput>
                    </Grid>
                    <Grid item className={classes.addButton}>
                      <ModalCreateButton
                        dialogResource="companies"
                        dialogFormField="operatingCompany"
                        dialogTitle="Add a Company"
                        actionTypeCreate
                      >
                        <CompanyFormField />
                      </ModalCreateButton>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item className={classes.gridItem} xs={12} sm={6} lg={4}>
                  <TextInput
                    fullWidth
                    label="Project Street Address"
                    source="streetAddress"
                  />
                </Grid>
                <Grid item className={classes.gridItem} xs={12} sm={6} lg={4}>
                  <TextInput fullWidth label="City" source="city" />
                </Grid>
                <Grid item className={classes.gridItem} xs={12} sm={6} lg={4}>
                  <TextInput fullWidth label="State" source="state" />
                </Grid>
                <Grid item className={classes.gridItem} xs={12} sm={6} lg={4}>
                  <TextInput fullWidth label="Country" source="country" />
                </Grid>
                <Grid item className={classes.gridItem} xs={12} sm={6} lg={4}>
                  <TextInput
                    fullWidth
                    label="Postal Code"
                    source="postalCode"
                  />
                </Grid>
                <Grid item className={classes.gridItem} xs={12} sm={6} lg={4}>
                  <TextInput
                    fullWidth
                    label="Purchase Land and Building"
                    source="purchaseLandAndBuilding"
                  />
                </Grid>
                <Grid item className={classes.gridItem} xs={12} sm={6} lg={4}>
                  <TextInput
                    fullWidth
                    label="Tenant Improvement"
                    source="tenantImprovement"
                  />
                </Grid>
                <Grid item className={classes.gridItem} xs={12} sm={6} lg={4}>
                  <TextInput
                    fullWidth
                    label="Eligible Fees"
                    source="eligibleFees"
                  />
                </Grid>
                <Grid item className={classes.gridItem} xs={12} sm={6} lg={4}>
                  <BooleanInput
                    fullWidth
                    label="Payment Penalty"
                    source="paymentPenalty"
                  />
                </Grid>
                <Grid item className={classes.gridItem} xs={6} sm={6} lg={6}>
                  <TextInput
                    multiline
                    fullWidth
                    label="Project General Description"
                    source="generalDescription"
                  />
                </Grid>
              </Grid>
            </div>
          </>
        </FormTab>
        <FormTab label={'Financing Sources'} path={'edit_financing'}>
          <Grid item className={classes.gridItem} xs={12} sm={6} lg={4}>
            <Grid container direction="row" alignItems="center" spacing={0}>
              <Grid item className={classes.nestedGridItem}>
                <ReferenceInput
                  source="interimLender"
                  reference="financing_sources"
                  label="Interim Lender"
                  filterToQuery={searchText => ({ title: searchText })}
                  format={v => {
                    return v instanceof Object ? v['@id'] : v;
                  }}
                  fullWidth
                >
                  <AutocompleteInput optionText="name" />
                </ReferenceInput>
              </Grid>
              <Grid item className={classes.addButton}>
                <ModalCreateButton
                  dialogResource="financing_sources"
                  dialogFormField="interimLender"
                  dialogTitle="Add a Financing Source"
                  actionTypeCreate
                >
                  <FinancingSourceFormField />
                </ModalCreateButton>
              </Grid>
            </Grid>
          </Grid>

          <ReferenceManyField
            label="Project Financing Sources"
            reference="project_financing_sources"
            target="project"
          >
            <>
              <Datagrid expand={<PostPanel />}>
                <TextField
                  source="lienPosition.position"
                  label="Lien Position"
                />
                <TextField
                  source="financingSource.name"
                  label="Financing Source"
                />
                <FunctionField
                  label="Amount"
                  render={record =>
                    `${
                      record.amount
                        ? '$' +
                          parseFloat(record.amount)
                            .toFixed(2)
                            .replace(/\d(?=(\d{3})+\.)/g, '$&,')
                        : '$0'
                    }`
                  }
                />
                <FunctionField
                  label="Rate"
                  render={record => record.rate}
                />
                <FunctionField
                  label="Monthly Payment"
                  render={record =>
                    `${
                      record.principalAndInterestPayment
                        ? '$' +
                          parseFloat(record.principalAndInterestPayment)
                            .toFixed(2)
                            .replace(/\d(?=(\d{3})+\.)/g, '$&,')
                        : '$0'
                    }`
                  }
                />
                <FunctionField
                  label="Percentage"
                  render={record => record.percentage + '%'}
                />
                <ModalEditButton
                  dialogResource="project_financing_sources"
                  dialogFormField="projectFinancingSources"
                  dialogTitle="Edit Project Financing Source"
                  dialogMergeFormValues={{ project: props.id }}
                  dialogRedirect={`/projects/${encodeURIComponent(
                    props.id
                  )}/edit_financing`}
                  dialogAddTextLabel="Edit"
                  actionTypeEdit
                >
                  <ProjectFinancingSourceFormFields projectId={props.id} />
                </ModalEditButton>
                <DeleteButton
                  redirect={`/projects/${encodeURIComponent(
                    props.id
                  )}/edit_financing`}
                />
              </Datagrid>
              <ModalCreateButton
                dialogResource="project_financing_sources"
                dialogFormField="projectFinancingSources"
                dialogTitle="Add a Financing Source"
                dialogMergeFormValues={{ project: props.id }}
                dialogRedirect={`/projects/${encodeURIComponent(
                  props.id
                )}/edit_financing`}
                dialogAddTextLabel="Add a Financing Source"
                actionTypeEdit
              >
                <ProjectFinancingSourceFormFields projectId={props.id} />
              </ModalCreateButton>
              <ProjectFinancingSourceSummary projectId={props.id} />
              <ProjectFinancingSourceAmortization projectId={props.id} />
            </>
          </ReferenceManyField>
        </FormTab>
        <FormTab label={'O.C. Info'} path={'edit_oc_owners'}>
          <div className={classes.root}>
            <Grid container direction="row" alignItems="center" spacing={6}>
              <Grid item className={classes.gridItem} xs={6} sm={6} lg={6}>
                <TextInput
                  multiline
                  fullWidth
                  label="O.C. General Description"
                  source="ocGeneralDescription"
                />
              </Grid>
              <Grid item className={classes.gridItem} xs={6} sm={6} lg={6}>
                <TextInput
                  multiline
                  fullWidth
                  label="O.C. Financial Description"
                  source="financialDescription"
                />
              </Grid>
              <Grid item className={classes.gridItem} xs={6} sm={6} lg={6}>
                <TextInput
                  multiline
                  fullWidth
                  label="O.C. Public Record Report"
                  source="publicRecordReport"
                />
              </Grid>
              <Grid item className={classes.gridItem} xs={6}>
                <TextInput
                    multiline
                    fullWidth
                    label="O.C. Credit Comments"
                    source="creditComments"
                />
              </Grid>
              <Grid item className={classes.gridItem} style={{alignSelf:'flex-start'}} xs={4}>
                <DateInput
                    fullWidth
                    label="Period Ending Date"
                    source="periodEndingDate"
                />
                <TextInput
                    className="muiAlignRight"
                    fullWidth
                    label="O.C. Monthly Free Cash Flow"
                    source="ocMonthlyFreeCashFlow"
                />
                <BooleanInput
                  className="subField"
                  fullWidth
                  label="projected cash flow"
                  source="ocMfcfProjected"
                />
              </Grid>
              <NoBasePathGrid item className={classes.gridItem} xs={4} style={{alignSelf:'flex-start'}}>
                <AssetsFormFields />
              </NoBasePathGrid>
              <NoBasePathGrid item className={classes.gridItem} xs={4} style={{alignSelf:'flex-start'}}>
                <LiabilitiesFormFields />
              </NoBasePathGrid>
            </Grid>
          </div>
          <ReferenceManyField
            label="Project Operating Company Ownership Percentages"
            reference="project_operating_company_ownerships"
            target="project"
          >
            <Datagrid>
              <FunctionField
                label="Contact"
                render={record =>
                  `${record.contact.firstName}` +
                  ` ` +
                  `${record.contact.lastName}`
                }
              />
              <FieldGuesser source="ownershipPercentage" />
              <ModalEditButton
                dialogResource="project_operating_company_ownerships"
                dialogFormField="projectOperatingCompanyOwnership"
                dialogTitle="Edit Project Operating Company Ownership"
                dialogMergeFormValues={{ project: props.id }}
                dialogRedirect={`/projects/${encodeURIComponent(
                  props.id
                )}/edit_oc_owners`}
                dialogAddTextLabel="Edit"
                actionTypeEdit
              >
                <ProjectOperatingCompanyFormFields />
              </ModalEditButton>
              <DeleteButton
                redirect={`/projects/${encodeURIComponent(
                  props.id
                )}/edit_oc_owners`}
              />
            </Datagrid>
          </ReferenceManyField>
          <ModalCreateButton
            dialogResource="project_operating_company_ownerships"
            dialogFormField="projectOperatingCompanyOwnership"
            dialogTitle="Add a Project Operating Company Ownership"
            dialogMergeFormValues={{ project: props.id }}
            dialogRedirect={`/projects/${encodeURIComponent(
              props.id
            )}/edit_oc_owners`}
            dialogAddTextLabel="Create"
            actionTypeEdit
          >
            <ProjectOperatingCompanyFormFields />
          </ModalCreateButton>
        </FormTab>
        <FormTab label={'Guarantors'} path="edit_guarantors">
          <ReferenceManyField
            label="Project Guarantors"
            reference="guarantors"
            target="project"
          >
            <Datagrid>
              <FunctionField
                label="Contact"
                render={record =>
                  `${record.contact.firstName}` +
                  ` ` +
                  `${record.contact.lastName}`
                }
              />
              <FieldGuesser source="ficoScore" />
              <FieldGuesser source="publicRecordClear" />
              <ModalEditButton
                dialogResource="guarantors"
                dialogFormField="guarantor"
                dialogTitle="Edit Guarantor"
                dialogMergeFormValues={{ project: props.id }}
                dialogRedirect={`/projects/${encodeURIComponent(
                  props.id
                )}/edit_guarantors`}
                dialogAddTextLabel="Edit"
                actionTypeEdit
              >
                <GuarantorFormField />
              </ModalEditButton>
              <DeleteButton
                redirect={`/projects/${encodeURIComponent(
                  props.id
                )}/edit_guarantors`}
              />
            </Datagrid>
          </ReferenceManyField>
          <ModalCreateButtonWithForm
            dialogResource="guarantors"
            dialogFormField="guarantor"
            dialogTitle="Add a Guarantor"
            dialogMergeFormValues={{ project: props.id }}
            dialogRedirect={`/projects/${encodeURIComponent(
              props.id
            )}/edit_guarantors`}
            dialogAddTextLabel="Create"
            actionTypeEdit
          >
            <GuarantorFormField />
          </ModalCreateButtonWithForm>
        </FormTab>
        <FormTab label={'Borrowers'} path={'edit_borrowers'}>
          <ReferenceManyField
            label="Project Borrowers"
            reference="borrowers"
            target="project"
          >
            <Datagrid expand={<BorrowerEditPanel redirectId={props.id} />}>
              <TextField source="company.name" label="Company" />
              <FunctionField
                label="Contact"
                render={record =>
                  record.contact
                    ? `${record.contact.firstName}` +
                      ` ` +
                      `${record.contact.lastName}`
                    : ''
                }
              />
              <FunctionField
                label="Borrower Company Ownerships"
                render={record =>
                  <ul className="noSpacing">
                    {record.borrowerCompanyOwnership.map(
                      (bco) => {
                        return <li>{bco.contact.name + ' | ' + bco.ownershipPercent + '%'}</li>
                      }
                    )}
                  </ul>}
              />
              <DeleteButton
                redirect={`/projects/${encodeURIComponent(
                  props.id
                )}/edit_borrowers`}
              />
            </Datagrid>
          </ReferenceManyField>
          <ModalCreateButton
            dialogResource="borrowers"
            dialogFormField="borrowers"
            dialogTitle="Add a Company Borrower"
            dialogMergeFormValues={{ project: props.id }}
            dialogRedirect={`/projects/${encodeURIComponent(
              props.id
            )}/edit_borrowers`}
            dialogAddTextLabel="Add a Borrower"
            actionTypeEdit
          >
            <BorrowerFormField />
          </ModalCreateButton>
        </FormTab>

        <FormTab label="Score" path={'project_score'}>
          <ProjectScore {...props} showActions={true} />
        </FormTab>

        <FormTab label="Additional Info" source="additional_info">
          <div id="additionalInfoContainer">

            <div className="ctrlGrp">
              <div className="ctrlGrpParent">
                <TextInput
                  fullWidth
                  label="SBA Authorization Number"
                  source="sbaAuthorizationNumber"
                />
              </div>
            </div>

            <div className="ctrlGrp">
              <div className="ctrlGrpParent">
                <ReferenceInput
                  source="environmentalReportStatus"
                  reference="environmental_report_statuses"
                  label="Environmental Report Status"
                  fullWidth
                  format={v => {
                    return v instanceof Object ? v['@id'] : v;
                  }}
                >
                  <SelectInput optionText="status" />
                </ReferenceInput>
              </div>

              <div className="ctrlGrpChildren">
                <Grid container spacing={2}>
                  <Grid item xs={3}>
                    <DateInput
                      fullWidth
                      label="Environmental Report Due Date"
                      source="environmentalReportDueDate"
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <DateInput
                      fullWidth
                      label="Environmental Report Submitted Date"
                      source="environmentalReportSubmittedDate"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextInput
                      fullWidth
                      multiline
                      label="Environmental Report Ordered By"
                      source="environmentalReportOrderedBy"
                    />
                  </Grid>
                </Grid>
              </div>
            </div>

            <div className="ctrlGrp">
              <div className="ctrlGrpParent">
                <ReferenceInput
                  source="appraisalReportStatus"
                  reference="appraisal_report_statuses"
                  label="Appraisal Report Status"
                  fullWidth
                  format={v => {
                    return v instanceof Object ? v['@id'] : v;
                  }}
                >
                  <SelectInput optionText="status" />
                </ReferenceInput>
              </div>

              <div className="ctrlGrpChildren">
                <Grid container spacing={2}>
                  <Grid item xs={3}>
                    <DateInput
                      fullWidth
                      label="Appraisal Report Due Date"
                      source="appraisalReportDueDate"
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <DateInput
                      fullWidth
                      label="Appraisal Report Submitted Date"
                      source="appraisalReportSubmittedDate"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextInput
                      fullWidth
                      multiline
                      label="Appraisal Report Ordered By"
                      source="appraisalReportOrderedBy"
                    />
                  </Grid>
                </Grid>
              </div>
            </div>
          </div>
        </FormTab>

        <FormTab label="Notes" path={'edit_notes'}>
          <ReferenceManyField
            label="Project Notes"
            reference="project_notes"
            target="project"
          >
            <>
              <Datagrid>
                <TextField source="note" />
                <DateField source="createdAt" showTime />
                <ModalEditButton
                  dialogResource="project_notes"
                  dialogFormField="note"
                  dialogTitle="Edit Note"
                  dialogMergeFormValues={{ project: props.id }}
                  dialogRedirect={`/projects/${encodeURIComponent(
                    props.id
                  )}/edit_notes`}
                  dialogAddTextLabel="Edit"
                  actionTypeEdit
                >
                  <ProjectNoteForm />
                </ModalEditButton>
                <DeleteButton
                  redirect={`/projects/${encodeURIComponent(props.id)}/edit_notes`}
                />
              </Datagrid>
              <ModalCreateButton
                dialogResource="project_notes"
                dialogFormField="note"
                dialogTitle="Create Note"
                dialogMergeFormValues={{ project: props.id }}
                dialogRedirect={`/projects/${encodeURIComponent(
                  props.id
                )}/edit_notes`}
                dialogAddTextLabel="Create"
                actionTypeEdit
              >
                <ProjectNoteForm />
              </ModalCreateButton>
              <ProjectNotesExportButton />

            </>
          </ReferenceManyField>
        </FormTab>
      </TabbedForm>
    </Edit>
  );
};
