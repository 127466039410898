import React from 'react';
import {
  BooleanInput,
  Edit,
  SimpleForm,
  NumberInput
} from 'react-admin';
import { WithProps, TopBar, NoDeleteToolbar } from './_common';

export const LoanToValueScoreEdit = props => {

  return (
    <Edit {...props} actions={<TopBar />} className="scoreCfgEdit">
      <WithProps>{({record,...props})=>
        <SimpleForm {...props} toolbar={<NoDeleteToolbar />} record={record}>
          <div>Score: {record.score}</div>
          <NumberInput source="minPercent" />
          <BooleanInput source="isApprovalThreshold" label="Approval Threshold" />
        </SimpleForm>}
      </WithProps>
    </Edit>
  );
};