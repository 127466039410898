import React from 'react';
import { NumberInput, FormDataConsumer } from 'react-admin';
import { Checkbox, FormControlLabel } from '@material-ui/core';

class TaxLienFields extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            has: (+this.props.record.taxLienCount > 0)
        }

        this.taxLienCount = React.createRef();
    }

    componentDidMount() {
        if ( ! this.state.has) {
            this.taxLienCount.current.parentNode.parentNode.style.display = 'none';
        }
    }

    handleHasTaxLienChange = (formData, e) => {
        const id = formData['@id'];
        const modalFormData = this.props.modalForm.getState().values.guarantors || [];
        modalFormData.map((value, index) => {
            if (value['@id'] === id) {
                this.props.modalForm.change('guarantors.'+index+'.taxLienCount', 0);
           }
        });

        formData.taxLienCount = 0;

        if (this.state.has) { // was in checked state when they clicked it, so set to unchecked state
            this.setState({
                has: false
            },
            (e) => {
                this.forceUpdate();
                this.taxLienCount.current.focus();
                this.taxLienCount.current.parentNode.parentNode.style.display = 'none';
            });
        } else { // was not checked, so set checked state
            this.setState({
                has: true
            },
            (e) => {
                this.forceUpdate();
                this.taxLienCount.current.parentNode.parentNode.style.display = 'inline-flex';
                this.taxLienCount.current.focus();
            });
        }
    }

    handleTaxLienCountBlur = (formData, e) => {
        if (formData.taxLienCount == null || formData.taxLienCount == 0) {
            const id = formData['@id'];
            const modalFormData = this.props.modalForm.getState().values.guarantors || [];
            modalFormData.map((value, index) => {
                if (value['@id'] === id) {
                    this.props.modalForm.change('guarantors.'+index+'.taxLienCount', 0);
                }
            });

            formData.taxLienCount = 0;

            this.setState({
                has: false
            },
            () => {
                this.forceUpdate();
                this.taxLienCount.current.focus();
                this.taxLienCount.current.parentNode.parentNode.style.display = 'none';
            });
        }
    }

    handleTaxLienCountChange = (formData, e) => {
        if (+formData.taxLienCount > 0) {
            this.setState({
                has: true
            });
        }
    }

    render() {
        return (
            <>
                <FormDataConsumer>
                    {({ formData, ...rest }) => {
                        return (
                            <>
                                <FormControlLabel
                                    value="yes"
                                    control={<Checkbox
                                        color="primary"
                                        inputRef={this.hasTaxLien}
                                        checked={this.state.has}
                                    />}
                                    label="Has Tax Liens?"
                                    labelPlacement="end"
                                    onChange={(event) => {this.handleHasTaxLienChange(formData, event)}}
                                />

                                <NumberInput
                                    fullWidth
                                    label="Tax Lien Count"
                                    source="taxLienCount"
                                    inputRef={this.taxLienCount}
                                    onChange={(event) => {this.handleTaxLienCountChange(formData, event)}}
                                    onBlur={(event) => {this.handleTaxLienCountBlur(formData, event)}}
                                />
                            </>
                        )
                    }}
                </FormDataConsumer>
            </>
        );
    }
}

export default TaxLienFields;
