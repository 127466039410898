import React from 'react';
import {TextInput} from 'react-admin';
import { currencyFormat } from '../../util';

class LiabilitiesFormFields extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            curr: 0,
            longTerm: 0,
            total: 0
        }
        this.handleFieldChange = this.handleFieldChange.bind(this);

        this.subordinatedDebtRef = React.createRef();
        this.otherDebtRef = React.createRef();
        this.currentLiabilitiesRef = React.createRef();
        this.longTermLiabilitiesRef = React.createRef();
    }

    updateTotal() {
        const subordinatedDebt = isNaN(parseFloat(this.subordinatedDebtRef.current.value)) ? 0 : parseFloat(this.subordinatedDebtRef.current.value);
        const otherDebt = isNaN(parseFloat(this.otherDebtRef.current.value)) ? 0 : parseFloat(this.otherDebtRef.current.value);
        const current = isNaN(parseFloat(this.currentLiabilitiesRef.current.value)) ? 0 : parseFloat(this.currentLiabilitiesRef.current.value);
        const longTerm = isNaN(parseFloat(this.longTermLiabilitiesRef.current.value)) ? 0 : parseFloat(this.longTermLiabilitiesRef.current.value);

        this.setState({
            subordinatedDebt: subordinatedDebt,
            otherDebt: otherDebt,
            curr: current,
            longTerm: longTerm,
            total: currencyFormat(subordinatedDebt + otherDebt + current + longTerm),
            scoringTotal: currencyFormat( current + longTerm)
        });
    }

    componentDidMount() {
        this.updateTotal();
    }

    handleFieldChange() {
        this.updateTotal();
    }

    render() {
        return (
            <div>
                <TextInput
                  className="muiAlignRight"
                  fullWidth
                  label="Subordinated Debt"
                  source="subordinatedDebt"
                  inputRef={this.subordinatedDebtRef}
                  onChange={this.handleFieldChange}
                />
                <TextInput
                  className="muiAlignRight"
                  fullWidth
                  label="Other Debt"
                  source="otherDebt"
                  inputRef={this.otherDebtRef}
                  onChange={this.handleFieldChange}
                />
                <TextInput
                    className="muiAlignRight"
                    fullWidth
                    label="Current Liabilities"
                    source="currentLiabilities"
                    inputRef={this.currentLiabilitiesRef}
                    onChange={this.handleFieldChange}
                />
                <TextInput
                    className="muiAlignRight"
                    fullWidth
                    label="Long Term Liabilities"
                    source="longTermLiabilities"
                    inputRef={this.longTermLiabilitiesRef}
                    onChange={this.handleFieldChange}
                />
                <div className="alignRight" style={{paddingRight:'10px'}}>TOTAL: {this.state.total}</div>
                <div className="alignRight" style={{paddingRight:'10px'}}>SCORING TOTAL: {this.state.scoringTotal}</div>
            </div>
        );
    }
}

export default LiabilitiesFormFields;