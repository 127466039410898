import React from 'react';
import {
  TextInput,
  NumberInput,
  DateInput,
  ReferenceInput,
  NullableBooleanInput,
  AutocompleteInput,
  BooleanInput
} from 'react-admin';
import { Grid } from '@material-ui/core';
import ModalCreateButton from '../helpers/ModalCreateButton';
import ContactFormField from '../contacts/ContactFormField';
import TaxLienFields from './TaxLienFields'
import LawsuitFields from "./LawsuitFields";
import { NoBasePathGrid } from "../helpers/WidgetCollection";
import BankruptcyFields from "./BankruptcyFields";

const GuarantorFormField = ({ record, modalForm }) => {
  return (
    <>
      <Grid container spacing={3} alignItems="center">
        <Grid item xs={12}>
          <Grid container spacing={0} justify="center" alignItems="center">
            <Grid item xs={11}>
              <ReferenceInput
                source="contact"
                reference="contacts"
                label="Contact"
                allowEmpty
                fullWidth
                format={v => {
                  return v instanceof Object ? v['@id'] : v;
                }}
              >
                <AutocompleteInput optionText="name" />
              </ReferenceInput>
            </Grid>
            <Grid item xs={1}>
              <ModalCreateButton
                dialogResource="contacts"
                dialogFormField="contact"
                dialogTitle="Add a Contact"
                actionTypeCreate
              >
                <ContactFormField />
              </ModalCreateButton>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={6}>
          <TextInput fullWidth label="Mo. Free Cash Flow" source="monthlyFreeCashFlow" />
          <BooleanInput
            className="subField"
            fullWidth
            label="projected cash flow"
            source="mfcfProjected"
          />
        </Grid>
        <Grid item xs={6} style={{alignSelf:'flex-start'}}>
          <DateInput
              fullWidth
              label="Period Ending Date"
              source="periodEndingDate"
          />
        </Grid>
        <Grid item xs={6}>
          <NumberInput fullWidth label="Fico Score" source="ficoScore" />
        </Grid>
        <Grid item xs={6}>
          <NullableBooleanInput
            style={{ width: '100%' }}
            label="Public Record Clear?"
            source="publicRecordClear"
          />
        </Grid>
        <NoBasePathGrid style={{alignSelf:'flex-start'}} item xs={6}>
          <TaxLienFields record={record} modalForm={modalForm} />
        </NoBasePathGrid>
        <NoBasePathGrid style={{alignSelf:'flex-start'}} item xs={6}>
          <LawsuitFields record={record} modalForm={modalForm} />
        </NoBasePathGrid>
        <NoBasePathGrid item xs={12}>
          <BankruptcyFields record={record} modalForm={modalForm} />
        </NoBasePathGrid>
        <Grid item xs={12}>
          <TextInput
              multiline
              fullWidth
              label="Guarantor Description"
              source="guarantorDescription"
          />
        </Grid>
      </Grid>
    </>
  );
};
export default GuarantorFormField;
