import React from 'react';
import { ResourceGuesser } from '@api-platform/admin';
import { LoanToValueScoreList } from "./LoanToValueScoreList";
import { LoanToValueScoreEdit } from "./LoanToValueScoreEdit";

export default (
  <ResourceGuesser
    name="loan_to_value_scores"
    list={LoanToValueScoreList}
    show={false}
    edit={LoanToValueScoreEdit}
    create={false}
  />
);