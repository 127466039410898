import React from 'react';
import { ResourceGuesser } from '@api-platform/admin';
import { GuarantorCharacterScoreList } from "./GuarantorCharacterScoreList";
import { GuarantorCharacterScoreEdit } from "./GuarantorCharacterScoreEdit";

export default (
  <ResourceGuesser
    name="guarantor_character_scores"
    list={GuarantorCharacterScoreList}
    show={false}
    edit={GuarantorCharacterScoreEdit}
    create={false}
  />
);