import React from 'react';
import projectResource from './projects/Projects';
import companyResource from './companies/Company';
import contactResource from './contacts/Contacts';
import financingSourceResource from './financing_sources/FinancingSources';
import lienPositionResource from './lien_positions/LienPosition';
import borrowerResource from './borrowers/Borrower';
import projectFinancingSourceResource from './project_financing_sources/ProjectFinancingSources';
import projectNoteResource from './project_notes/ProjectNotes';
import borrowerCompanyOwnershipResource from './borrower_company_ownership/BorrowerCompanyOwnership';
import projectOperatingCompanyOwnershipResource from './project_operating_company_ownership/ProjectOperatingCompanyOwnerships';
import guarantorResource from './guarantors/Guarantors';
import debtServiceRatioResource from './debtServiceRatio/DebtServiceRatio';
import usersResource from './users/User';
import inviteResource from './invite/index';
import debtServiceCoverageRatioScoreResource from "./score_rules/DebtServiceCoverageRatioScore";
import balanceSheetRatioScoreResource from "./score_rules/BalanceSheetRatioScore";
import ficoCreditAverageScoreResource from "./score_rules/FicoCreditAverageScore";
import loanToValueScoreResource from "./score_rules/LoanToValueScore";
import guarantorCharacterScoreResource from "./score_rules/GuarantorCharacterScore";
import environmentalReportStatusResource from "./environmental_report_statuses/EnvironmentalReportStatus";
import appraisalReportStatusResource from "./appraisal_report_statuses/AppraisalReportStatus";
// import adminDashboard from './admin_dashboard/AdminDashboard'
// import staffUserResource from './staff_users/StaffUsers';
// eslint-disable-next-line import/no-anonymous-default-export
export default [
  projectResource,
  companyResource,
  contactResource,
  financingSourceResource,
  lienPositionResource,
  borrowerResource,
  projectFinancingSourceResource,
  borrowerCompanyOwnershipResource,
  projectOperatingCompanyOwnershipResource,
  guarantorResource,
  debtServiceRatioResource,
  usersResource,
  inviteResource,
  // adminDashboard,
  // staffUserResource
  projectNoteResource,
  debtServiceCoverageRatioScoreResource,
  balanceSheetRatioScoreResource,
  ficoCreditAverageScoreResource,
  loanToValueScoreResource,
  guarantorCharacterScoreResource,
  environmentalReportStatusResource,
  appraisalReportStatusResource
];
