import React from 'react';
import { NumberInput, FormDataConsumer } from 'react-admin';
import { Checkbox, FormControlLabel } from '@material-ui/core';

class LawsuitFields extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            has: (+this.props.record.lawsuitCount > 0)
        }

        this.lawsuitCount = React.createRef();
    }

    componentDidMount() {
        if ( ! this.state.has) {
            this.lawsuitCount.current.parentNode.parentNode.style.display = 'none';
        }
    }

    handleHasLawsuitChange = (formData, e) => {
        const id = formData['@id'];
        const modalFormData = this.props.modalForm.getState().values.guarantors || [];
        modalFormData.map((value, index) => {
            if (value['@id'] === id) {
                this.props.modalForm.change('guarantors.'+index+'.lawsuitCount', 0);
            }
        });

        formData.lawsuitCount = 0;

        if (this.state.has) { // was in checked state when they clicked it, so set to unchecked state
            this.setState({
                    has: false
                },
                (e) => {
                    this.forceUpdate();
                    this.lawsuitCount.current.focus();
                    this.lawsuitCount.current.parentNode.parentNode.style.display = 'none';
                });
        } else { // was not checked, so set checked state
            this.setState({
                    has: true
                },
                (e) => {
                    this.forceUpdate();
                    this.lawsuitCount.current.parentNode.parentNode.style.display = 'inline-flex';
                    this.lawsuitCount.current.focus();
                });
        }
    }

    handleLawsuitCountBlur = (formData, e) => {
        if (formData.lawsuitCount == null || formData.lawsuitCount == 0) {
            const id = formData['@id'];
            const modalFormData = this.props.modalForm.getState().values.guarantors || [];
            modalFormData.map((value, index) => {
                if (value['@id'] === id) {
                    this.props.modalForm.change('guarantors.'+index+'.lawsuitCount', 0);
                }
            });

            formData.lawsuitCount = 0;

            this.setState({
                    has: false
                },
                () => {
                    this.forceUpdate();
                    this.lawsuitCount.current.focus();
                    this.lawsuitCount.current.parentNode.parentNode.style.display = 'none';
                });
        }
    }

    handleLawsuitCountChange = (formData, e) => {
        if (+formData.lawsuitCount > 0) {
            this.setState({
                has: true
            });
        }
    }

    render() {
        return (
            <>
                <FormDataConsumer>
                    {({ formData, ...rest }) => {
                        return (
                            <>
                                <FormControlLabel
                                    value="yes"
                                    control={<Checkbox
                                        color="primary"
                                        inputRef={this.hasLawsuit}
                                        checked={this.state.has}
                                    />}
                                    label="Has Lawsuits?"
                                    labelPlacement="end"
                                    onChange={(event) => {this.handleHasLawsuitChange(formData, event)}}
                                />

                                <NumberInput
                                    fullWidth
                                    label="Lawsuit Count"
                                    source="lawsuitCount"
                                    inputRef={this.lawsuitCount}
                                    onChange={(event) => {this.handleLawsuitCountChange(formData, event)}}
                                    onBlur={(event) => {this.handleLawsuitCountBlur(formData, event)}}
                                />
                            </>
                        )
                    }}
                </FormDataConsumer>
            </>
        );
    }
}

export default LawsuitFields;
