import React from 'react';
import { ResourceGuesser } from '@api-platform/admin';
import { AppraisalReportStatusList } from './AppraisalReportStatusList';

export default (
  <ResourceGuesser
    name="appraisal_report_statuses"
    list={AppraisalReportStatusList}
    show={false}
  />
);
