import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useMediaQuery } from '@material-ui/core';
import { DashboardMenuItem, MenuItemLink } from 'react-admin';
import ContactsIcon from '@material-ui/icons/Contacts';
import BusinessIcon from '@material-ui/icons/Business';
import MoneyIcon from '@material-ui/icons/Money';
import DashboardIcon from '@material-ui/icons/Dashboard';
import AssignmentIcon from '@material-ui/icons/Assignment';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import FormatListNumberedIcon from '@material-ui/icons/FormatListNumbered';
import SubMenu from './SubMenu';

const MySideMenu = ({ onMenuClick, toggleSidebar, logout }) => {
  const isXSmall = useMediaQuery(theme => theme.breakpoints.down('xs'));
  const open = useSelector(state => state.admin.ui.sidebarOpen);
  const [state, setState] = useState({
    menuConfigurations: true,
    menuScoreRules: false
  });
  const handleToggle = menu => {
    setState(state => ({ ...state, [menu]: !state[menu] }));
  };

  return (
    <div id="sideMenu">
      {/*<MenuItemLink to = '/home' primaryText = 'Home' */}
      {/*leftIcon = {<DashboardIcon onClick = {onMenuClick}/>}*/}
      {/*sidebarIsOpen = {open} />*/}
      <MenuItemLink
        to="/projects"
        primaryText="Projects"
        leftIcon={<AssignmentIcon onClick={onMenuClick} />}
        sidebarIsOpen={open}
      />
      <MenuItemLink
        to="/contacts"
        primaryText="Contacts"
        leftIcon={<ContactsIcon onClick={onMenuClick} />}
        sidebarIsOpen={open}
      />
      <MenuItemLink
        to="/companies"
        primaryText="Companies"
        leftIcon={<BusinessIcon onClick={onMenuClick} />}
        sidebarIsOpen={open}
      />
      <MenuItemLink
        to="/users"
        primaryText="Staff"
        leftIcon={<DashboardIcon onClick={onMenuClick} />}
        sidebarIsOpen={open}
      />

      <span className="subMenu">
        <SubMenu
          handleToggle={() => handleToggle('menuConfigurations')}
          isOpen={state.menuConfigurations}
          sidebarIsOpen={open}
          name="Configurations"
          icon={<ChevronRightIcon />}
        >
          <MenuItemLink
            to="/financing_sources"
            primaryText="Financing Sources"
            leftIcon={<MoneyIcon onClick={onMenuClick} />}
            sidebarIsOpen={open}
          />
          <MenuItemLink
            to="/lien_positions"
            primaryText="Lien Positions"
            leftIcon={<FormatListNumberedIcon onClick={onMenuClick} />}
            sidebarIsOpen={open}
          />
        </SubMenu>
      </span>

      <span className="subMenu">
        <SubMenu
          handleToggle={() => handleToggle('menuScoreRules')}
          isOpen={state.menuScoreRules}
          sidebarIsOpen={open}
          name="Score Rules"
          icon={<ChevronRightIcon />}
        >
          <MenuItemLink
            to="/debt_service_coverage_ratio_scores"
            primaryText="Debt Service Coverage"
            leftIcon={<FormatListNumberedIcon onClick={onMenuClick} />}
            sidebarIsOpen={open}
          />
          <MenuItemLink
            to="/balance_sheet_ratio_scores"
            primaryText="Balance Sheet"
            leftIcon={<FormatListNumberedIcon onClick={onMenuClick} />}
            sidebarIsOpen={open}
          />
          <MenuItemLink
            to="/fico_credit_average_scores"
            primaryText="FICO Credit Average"
            leftIcon={<FormatListNumberedIcon onClick={onMenuClick} />}
            sidebarIsOpen={open}
          />
          <MenuItemLink
            to="/loan_to_value_scores"
            primaryText="Loan to Value"
            leftIcon={<FormatListNumberedIcon onClick={onMenuClick} />}
            sidebarIsOpen={open}
          />
          <MenuItemLink
            to="/guarantor_character_scores"
            primaryText="Guarantor Character"
            leftIcon={<FormatListNumberedIcon onClick={onMenuClick} />}
            sidebarIsOpen={open}
          />
        </SubMenu>
      </span>

      {isXSmall && logout}
    </div>
  );
};

export default MySideMenu;
