import React, { useState, useCallback } from 'react';
import {useForm, useFormState} from 'react-final-form';
import { makeStyles } from '@material-ui/core/styles';
import QuickCreateButton from './QuickCreateButton';
const useStyles = makeStyles({
  root: {
    display: 'flex',
    alignItems: 'center'
  }
});
const spySubscription = { values: true };
const ModalCreateButtonWithForm = props => {
  const { disabled } = props;
  const classes = useStyles();
  const [version, setVersion] = useState(0);
  const { values } = useFormState({ subscription: spySubscription });
  const form = useForm();
  const handleChange = useCallback(
    data => {
      return setVersion(version + 1);
    },
    [version]
  );

  return (
    <QuickCreateButton
      onChange={handleChange}
      {...props}
      defaultValues={{taxLienCount:0, lawsuitCount:0}}
    >
      {React.Children.map(props.children, child =>
        React.cloneElement(child, { record: props.record, modalForm: form })
      )}
    </QuickCreateButton>
  );
};
export default ModalCreateButtonWithForm;
