import React from "react";
import { Grid, Typography } from "@material-ui/core";
import { ListButton, SaveButton, Toolbar } from "react-admin";

export const WithProps = ({children,...props}) => children(props);

export const TopBar = props => {
  return (
    <div className="scoreCfgCon">
      <Grid container direction="row" alignItems="center" spacing={6}>
        <Grid item xs={8}>
          <Typography variant="h4" color="secondary" className="title">
            Edit Debt Service Coverage Ratio Score
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <ListButton basePath={props.basePath} />
        </Grid>
      </Grid>
    </div>
  );
};

export const NoDeleteToolbar = props => {
  return (
    <Toolbar {...props} >
      <SaveButton />
    </Toolbar>
  );
};