import React from 'react';
import {
  BooleanInput,
  Edit,
  SimpleForm,
  TextInput
} from 'react-admin';
import { WithProps, TopBar, NoDeleteToolbar } from './_common';

export const BalanceSheetRatioScoreEdit = props => {

  return (
    <Edit {...props} actions={<TopBar />} className="scoreCfgEdit">
      <WithProps>{({record,...props})=>
        <SimpleForm {...props} toolbar={<NoDeleteToolbar />} record={record}>
          <div>Score: {record.score}</div>
          <TextInput source="minRatio" />
          <BooleanInput source="isApprovalThreshold" label="Approval Threshold" />
        </SimpleForm>}
      </WithProps>
    </Edit>
  );
};