import React from 'react';
import { ResourceGuesser } from '@api-platform/admin';
import { BalanceSheetRatioScoreList } from "./BalanceSheetRatioScoreList";
import { BalanceSheetRatioScoreEdit } from "./BalanceSheetRatioScoreEdit";

export default (
  <ResourceGuesser
    name="balance_sheet_ratio_scores"
    list={BalanceSheetRatioScoreList}
    show={false}
    edit={BalanceSheetRatioScoreEdit}
    create={false}
  />
);