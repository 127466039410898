import React from 'react';
import { ResourceGuesser } from '@api-platform/admin';
import { DebtServiceCoverageRatioScoreList } from "./DebtServiceCoverageRatioScoreList";
import { DebtServiceCoverageRatioScoreEdit } from './DebtServiceCoverageRatioScoreEdit';


export default (
  <ResourceGuesser
    name="debt_service_coverage_ratio_scores"
    list={DebtServiceCoverageRatioScoreList}
    show={false}
    edit={DebtServiceCoverageRatioScoreEdit}
    create={false}
  />
);