import React from 'react';
import { useListContext } from 'react-admin';
import {
  Grid,
  Typography,
  Table,
  TableBody,
  TableHead,
  TableCell,
  TableRow
} from '@material-ui/core';
import { currencyFormat } from '../../util';
import './_project_financing_sources.css';

class Collapsible extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      open: false
    }
    this.togglePanel = this.togglePanel.bind(this);
  }

  togglePanel(e){
    this.setState({open: !this.state.open})
  }

  render() {
    if (this.props.title == 'Personal') {
      return '';
    }

    const className = (this.state.open) ? 'header open' : 'header closed';

    return (<div>
      <div onClick={(e)=>this.togglePanel(e)} className={className}>
        {this.props.title}</div>
      {this.state.open ? (
        <div className='content'>
          {this.props.children}
        </div>
      ) : null}
    </div>);
  }
}

const ProjectFinancingSourceAmortization = props => {
  const { data } = useListContext();

  //If there's no data, return an empty component
  if (!data || Object.keys(data).length === 0) return null;

  return (
    <div style={{ width: '100%', marginTop: '1.5rem' }}>
      <Grid container spacing={4} alignItems="center">
        <Grid item xs={12}>
          <Typography variant="h5">
            Amortization Schedules
          </Typography>
        </Grid>

        <Grid item xs={12} className="amortTbl">
          {Object.keys(data).map((key, index) => (
            <Collapsible title={data[key].financingSource.name}>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>Month</TableCell>
                    <TableCell>Principal</TableCell>
                    <TableCell>Interest</TableCell>
                    <TableCell>Total Interest</TableCell>
                    <TableCell>Balance</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data[key].amortizationSchedule.map(row => (
                    <TableRow key={key + row.month}>
                      <TableCell>{row.month}</TableCell>
                      <TableCell>{currencyFormat(row.toPrincipal)}</TableCell>
                      <TableCell>{currencyFormat(row.toInterest)}</TableCell>
                      <TableCell>{currencyFormat(row.totalInterest)}</TableCell>
                      <TableCell>{currencyFormat(row.balance)}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
              <br />
            </Collapsible>
          ))}
        </Grid>

      </Grid>
    </div>
  );
};

export default ProjectFinancingSourceAmortization;
