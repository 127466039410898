import React from 'react';
import { ResourceGuesser } from '@api-platform/admin';
import { EnvironmentalReportStatusList } from './EnvironmentalReportStatusList';

export default (
  <ResourceGuesser
    name="environmental_report_statuses"
    list={EnvironmentalReportStatusList}
    show={false}
  />
);
