import React from 'react';
import { Button } from '@material-ui/core';
import jsPDF from 'jspdf';
import { useListContext } from 'ra-core';
import 'jspdf-autotable';

const ProjectNotesExportButton = () => {
  const { data } = useListContext();
  if (!data || Object.keys(data).length === 0) return null;
  const download = () => {
    const pdf = jsPDF('p', 'pt');
    var columns = [
      {title: "Notes", dataKey: "note"},
      {title: "Created At", dataKey: "createdAt"}
    ];
    pdf.autoTable(columns, Object.values(data).map((row) => ({
        note: String(row.note),
        createdAt: String(row.createdAt)
      })), [
        { id: 'note', name: 'note'},
        { id: 'createdAt', name: 'createdAt'}
      ]);
    pdf.save('notes.pdf');
  };
  return <Button onClick={download}>Export as PDF</Button>;
};

export default ProjectNotesExportButton;
