import React from 'react';
import {
  ReferenceInput,
  AutocompleteInput,
  NumberInput,
  TextInput,
  SelectInput,
  required
} from 'react-admin';
import FinancingSourceFormField from '../financing_sources/FinancingSourceFormField';
import ModalCreateButton from '../helpers/ModalCreateButton';
import { Grid, Divider } from '@material-ui/core';

const ProjectFinancingSourceFormFields = ({ record, projectId }) => {
  return (
    <form>
      <Divider style={{ marginBottom: '1.5rem' }} />
      <Grid container justify="center" alignItems="center" spacing={2} className="ndrModalFormContainer">
        <Grid container alignItems="center" spacing={1}>
          <Grid item xs={12}>
            <Grid container direction="row" alignItems="center" spacing={0}>
              <Grid item xs={11}>
                <ReferenceInput
                  source="financingSource"
                  reference="financing_sources"
                  label="Financing Source"
                  fullWidth
                  validate={[required()]}
                  format={v => {
                    return v instanceof Object ? v['@id'] : v;
                  }}
                >
                  <AutocompleteInput optionText="name" />
                </ReferenceInput>
              </Grid>
              <Grid item xs={1}>
                <ModalCreateButton
                  dialogResource="financing_sources"
                  dialogFormField="financingSource"
                  dialogTitle="Add a Financing Source"
                  actionTypeCreate
                >
                  <FinancingSourceFormField />
                </ModalCreateButton>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={6}>
            <ReferenceInput
              source="lienPosition"
              reference="lien_positions"
              label="Lien Position"
              fullWidth
              format={v => {
                return v instanceof Object ? v['@id'] : v;
              }}
            >
              <SelectInput optionText="position" />
            </ReferenceInput>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextInput
              validate={[]}
              fullWidth
              label="Amount"
              source="amount"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <NumberInput
              validate={[]}
              fullWidth
              label="Amortization"
              source="amortization"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextInput validate={[]} fullWidth label="Rate" source="rate" />
          </Grid>
          <Grid item xs={12} sm={6}>
            <NumberInput validate={[]} fullWidth label="Term" source="term" />
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
};
export default ProjectFinancingSourceFormFields;
