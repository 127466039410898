export const currencyFormat = value =>
  '$' +
  parseFloat(value)
    .toFixed(2)
    .replace(/\d(?=(\d{3})+\.)/g, '$&,');

export const ArrayToUnorderedList = ({data}) => {
  if (data) {
    return (
      <ul>
        {data.map((val, idx) => {
          return <li>{val}</li>
        })}
      </ul>
    )
  } else {
    return null;
  }
}