import React from 'react';
import { TextInput, ReferenceInput, SelectInput, required } from 'react-admin';
import { Grid, Typography } from '@material-ui/core';
import ModalCreateButton from '../helpers/ModalCreateButton';
import ContactFormField from '../contacts/ContactFormField';

const BorrowerCompanyOwnershipFormField = ({ record }) => {
  return (
    <>
      <Grid container spacing={2}>
        <Grid container spacing={2} justify="center" alignItems="center">
          <Grid item xs={10} sm={10}>
            <ReferenceInput
              source="contact"
              reference="contacts"
              label="Contact"
              format={v => {
                if (v === undefined) {
                  return '';
                }
                return v instanceof Object ? v['@id'] : v;
              }}
              allowEmpty
              fullWidth
            >
              <SelectInput
                optionText="name"
                validate={[required()]}
                defaultValue={record => {
                  return (record === undefined) ? '' : record.contact['name'];
                }}
              />
            </ReferenceInput>
          </Grid>
          <Grid item xs={2} sm={1}>
            <ModalCreateButton
              dialogResource="contacts"
              dialogFormField="contact"
              dialogTitle="Add a Contact"
              actionTypeCreate
            >
              <ContactFormField />
            </ModalCreateButton>
          </Grid>
          <Grid item xs={11} sm={11}>
            <TextInput
              fullWidth
              label="Ownership Percentage"
              source="ownershipPercent"
              validate={[required()]}
            />
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};
export default BorrowerCompanyOwnershipFormField;
