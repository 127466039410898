import React from 'react';
import { NumberInput, FormDataConsumer } from 'react-admin';
import { Checkbox, FormControlLabel } from '@material-ui/core';

class BankruptcyFields extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      has: (+this.props.record.bankruptcyCount > 0)
    }

    this.bankruptcyCount = React.createRef();
  }

  componentDidMount() {
    if ( ! this.state.has) {
      this.bankruptcyCount.current.parentNode.parentNode.style.display = 'none';
    }
  }

  handleHasBankruptcyChange = (formData, e) => {
    const id = formData['@id'];
    const modalFormData = this.props.modalForm.getState().values.guarantors || [];
    modalFormData.map((value, index) => {
      if (value['@id'] === id) {
        this.props.modalForm.change('guarantors.'+index+'.bankruptcyCount', 0);
      }
    });

    formData.bankruptcyCount = 0;

    if (this.state.has) { // was in checked state when they clicked it, so set to unchecked state
      this.setState({
          has: false
        },
        (e) => {
          this.forceUpdate();
          this.bankruptcyCount.current.focus();
          this.bankruptcyCount.current.parentNode.parentNode.style.display = 'none';
        });
    } else { // was not checked, so set checked state
      this.setState({
          has: true
        },
        (e) => {
          this.forceUpdate();
          this.bankruptcyCount.current.parentNode.parentNode.style.display = 'inline-flex';
          this.bankruptcyCount.current.focus();
        });
    }
  }

  handleBankruptcyCountBlur = (formData, e) => {
    if (formData.bankruptcyCount == null || formData.bankruptcyCount == 0) {
      const id = formData['@id'];
      const modalFormData = this.props.modalForm.getState().values.guarantors || [];
      modalFormData.map((value, index) => {
        if (value['@id'] === id) {
          this.props.modalForm.change('guarantors.'+index+'.bankruptcyCount', 0);
        }
      });

      formData.bankruptcyCount = 0;

      this.setState({
          has: false
        },
        () => {
          this.forceUpdate();
          this.bankruptcyCount.current.focus();
          this.bankruptcyCount.current.parentNode.parentNode.style.display = 'none';
        });
    }
  }

  handleBankruptcyCountChange = (formData, e) => {
    if (+formData.bankruptcyCount > 0) {
      this.setState({
        has: true
      });
    }
  }

  render() {
    return (
      <>
        <FormDataConsumer>
          {({ formData, ...rest }) => {
            return (
              <>
                <FormControlLabel
                  value="yes"
                  control={<Checkbox
                    color="primary"
                    inputRef={this.hasBankruptcy}
                    checked={this.state.has}
                  />}
                  label="Has Bankruptcies in the Last 10 Years?"
                  labelPlacement="end"
                  onChange={(event) => {this.handleHasBankruptcyChange(formData, event)}}
                />

                <NumberInput
                  fullWidth
                  label="Bankruptcy Count"
                  source="bankruptcyCount"
                  inputRef={this.bankruptcyCount}
                  onChange={(event) => {this.handleBankruptcyCountChange(formData, event)}}
                  onBlur={(event) => {this.handleBankruptcyCountBlur(formData, event)}}
                />
              </>
            )
          }}
        </FormDataConsumer>
      </>
    );
  }
}

export default BankruptcyFields;
