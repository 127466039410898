import React from 'react';
import {
  Create,
  SimpleForm,
  TextInput,
  RadioButtonGroupInput
} from 'react-admin';
import { EditActions } from '../actions/EditActions';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

const useStyles = makeStyles(theme => ({
  root: {
    width: '80%',
    display: 'flex',
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  gridItem: {
    width: '15%'
  }
}));

const StaffTitle = ({ record }) => {
  return 'Create a New Staff Account';
};

export const UserCreate = props => {
  const classes = useStyles();
  return (
    <Create title={<StaffTitle />} actions={<EditActions />} {...props}>
      <SimpleForm>
        <div className={classes.root}>
          <Grid
            container
            direction="row"
            justify="center"
            alignItems="center"
            spacing={3}
          >
            <TextInput fullWidth label="First Name" source="firstName" />
            <TextInput fullWidth label="Last Name" source="lastName" />
            <TextInput fullWidth label="Email Address" source="email" />
            <RadioButtonGroupInput
              fullWidth
              label="Role"
              source="asRole"
              choices={[
                { id: 'staff', name: 'Staff' },
                { id: 'admin', name: 'Admin' }
              ]}
            />
          </Grid>
        </div>
      </SimpleForm>
    </Create>
  );
};
