import React from 'react';
import {
  TextField,
  List,
  Filter,
  Datagrid,
  EditButton,
  ShowButton,
  DateField,
  FunctionField,
  TextInput,
  SelectArrayInput,
  ReferenceArrayInput
} from 'react-admin';
import DescriptionIcon from '@material-ui/icons/Description';
import Typography from "@material-ui/core/Typography";

const ProjectsListFilter = (props) => (
  <Filter {...props} className="projectListFilter">
    <TextInput label="Op. Company" source="operatingCompany.name" alwaysOn />
    <TextInput label="City" source="city" alwaysOn />
    <SelectArrayInput label="Project Approval Status" source="approvalStatus" alwaysOn className="longLabel" choices={[
      { id: 'PD', name: 'Pending' },
      { id: 'NR', name: 'Needs Review' },
      { id: 'AP', name: 'Approved' },
      { id: 'RJ', name: 'Rejected' },
    ]} />
    <ReferenceArrayInput label="Environmental Report Status" source="environmentalReportStatus" reference="environmental_report_statuses" className="longLabel" alwaysOn>
      <SelectArrayInput optionText="status" />
    </ReferenceArrayInput>
    <ReferenceArrayInput label="Appraisal Report Status" source="appraisalReportStatus" reference="appraisal_report_statuses" className="longLabel" alwaysOn>
      <SelectArrayInput optionText="status" />
    </ReferenceArrayInput>
  </Filter>
);

export const ProjectsList = props => {
  return (
    <>
      <Typography variant="h4" color="secondary" style={{fontSize:"1.5rem"}}>Projects</Typography>
      <List
        {...props}
        filters={<ProjectsListFilter />}
        exporter={false}
      >
        <Datagrid>
          <TextField source="operatingCompany.name" label="Operating Company" />
          <FunctionField
            label="Total Budget"
            render={record =>
              `$${(
                parseFloat(record.purchaseLandAndBuilding) +
                parseFloat(record.tenantImprovement) +
                parseFloat(record.eligibleFees)
              )
                .toFixed(2)
                .replace(/\d(?=(\d{3})+\.)/g, '$&,')}`
            }
          />
          <TextField label="Appr. Status" source="approvalStatusDisplay" />
          <TextField source="city" />
          <DateField source="createdAt" />
          <DateField source="updatedAt" />
          <EditButton />
          <ShowButton label="View Report" icon={<DescriptionIcon />} />
        </Datagrid>
      </List>
    </>
  );
};
